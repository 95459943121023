import { TextField, styled } from '@mui/material';

// TODO_NEXT - move the file in /StyledWrappers
const StyledFormInput = styled(TextField)(({ theme }) => ({
	marginBottom: 0,
	border: 'none',

	'& .MuiInputLabel-root': {
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.15px',
		color: 'text.active',
		height: '56px',

		'&.Mui-focused': {
			color: 'text.active',
			fontWeight: 400,
		},
	},

	'& .MuiInputBase-formControl': {
		borderRadius: '8px',
		backgroundColor: theme.palette.background.surfaceLowest,
		border: 'none',

		'&::after, ::before': {
			border: 'transparent',
		},

		'&:hover:not(.Mui-disabled, .Mui-error)::before': {
			border: 'transparent',
		},

		fieldset: {
			border: 'none',
		},
	},

	'& .MuiInputBase-input': {
		padding: '18px 12px',
		border: 'none',
	},
}));

export default StyledFormInput;
