import React, { lazy, Suspense } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { announcementsBtnActionsSteps } from 'store/common/keys';
import Spinner from 'components/Common/Spinner';

const BtnAnnouncementCreateProject = lazy(() => import('./BtnAnnouncementCreateProject'));
const BtnAnnouncementOpenSubscriptionModal = lazy(
	() => import('./BtnAnnouncementOpenSubscriptionModal'),
);

const { NEW_PROJECT, OPEN_SUBSCRIPTIONS_MODAL } = announcementsBtnActionsSteps;

export type BtnAnnouncementProps = {
	title: string;
	actionSteps: Array<string>;
	variant: ButtonProps['variant'];
};

const BtnAnnouncementAction: React.FC<BtnAnnouncementProps> = ({ title, actionSteps, variant }) => {
	const conditionalContent = () => {
		const isCreateProject = actionSteps.includes(NEW_PROJECT);
		const isOpenSubscriptionModal = actionSteps.includes(OPEN_SUBSCRIPTIONS_MODAL);

		if (isCreateProject) {
			return (
				<Suspense fallback={<Spinner size={20} margin="0" />}>
					<BtnAnnouncementCreateProject
						title={title}
						actionSteps={actionSteps}
						variant={variant}
					/>
				</Suspense>
			);
		}

		if (isOpenSubscriptionModal) {
			return (
				<Suspense fallback={<Spinner size={20} margin="0" />}>
					<BtnAnnouncementOpenSubscriptionModal variant={variant} title={title} />
				</Suspense>
			);
		}

		return null;
	};

	return conditionalContent();
};

export default BtnAnnouncementAction;
