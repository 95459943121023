import React, { RefObject, useState } from 'react';
import {
	TextField,
	Box,
	styled,
	FormControlLabel,
	Checkbox,
	Typography,
	SxProps,
	Theme,
} from '@mui/material';
import strings from 'constants/strings';

const { inputPromoCodePlaceholder, checkboxHavePromoCode } = strings;

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	margin: '0',
	flex: '0 0 auto',

	'.MuiCheckbox-root': {
		color: theme.palette.text.active,
		padding: '0',
		margin: '0 6px 0 0',
		transition: '0.3s',

		'&.Mui-checked': {
			color: theme.palette.primary.main,
		},
	},

	'.MuiTypography-root': {
		transition: '0.3s',
		lineHeight: '1',
		color: theme.palette.text.active,
	},

	'.Mui-checked + .MuiTypography-root': {
		color: theme.palette.text.hover,
	},

	'&:hover': {
		'.MuiCheckbox-root, .MuiTypography-root': {
			color: theme.palette.text.hover,
		},

		'.Mui-checked': {
			color: theme.palette.primary.light,
		},
	},
}));

const StyledWrapper = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: '11px',
	flexWrap: 'wrap',
	minHeight: '32px',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	'.MuiInputBase-root': {
		height: '32px',
		minWidth: '80px',
		flexShrink: '1',
		backgroundColor: theme.palette.background.surfaceLow,
	},
	'.MuiInputBase-input': {
		padding: '2px 12px',
	},
	'.MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent',
		borderRadius: '8px',
	},
}));

type Props = {
	labelText?: string;
	inputRef: RefObject<HTMLInputElement | null>;
	sx?: SxProps<Theme>;
};

const PromoCodeElements: React.FC<Props> = ({
	labelText = checkboxHavePromoCode,
	sx,
	inputRef,
}) => {
	const [havePromoCode, setHavePromoCode] = useState(false);

	return (
		<StyledWrapper sx={sx}>
			<StyledFormControlLabel
				control={
					<Checkbox
						checked={havePromoCode}
						onChange={() => {
							setHavePromoCode((p) => !p);
						}}
						size="small"
					/>
				}
				label={<Typography variant="body2">{labelText}</Typography>}
			/>
			{havePromoCode && (
				<StyledTextField inputRef={inputRef} placeholder={inputPromoCodePlaceholder} />
			)}
		</StyledWrapper>
	);
};

export default PromoCodeElements;
