import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { MoreVert } from '@mui/icons-material';

import { validateImageForSourceImage } from 'utils/imageUtils';
import strings from 'constants/strings';
import StyledMenu from 'components/StyledWrappers/StyledMenu';
import StyledMenuItem from 'components/StyledWrappers/StyledMenuItem';

import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { drawersKeys, generationToolsKeys } from 'store/common/keys';
import { showNotification } from 'store/storeSlices/sliceNotification';
import {
	openDrawerWithType,
	setControlNetCurrentOpenedTool,
	setControlNetSourceImage,
	setEnhanceSourceImageData,
	setPolishSourceImageData,
	resetPolishTool,
	setGenerationTool,
	setGenerationToolEnhanceSettings,
	setIsGenerationContainerHidden,
	setTransformSourceImageData,
	setGenerationToolImageToImageSettings,
	setIsInPaintToolOn,
	resetInPaintTool,
} from 'store/storeSlices/sliceOpenedProjects';
import { EnhanceGenerationData, ImageToImageGenerationData } from 'store/types/typesCommon';
import commonUtils from 'store/common/utils';
import { useFetchControlNetToolsQuery } from 'store/apis/apiControlNetTools';
import { GenerationImagePreview } from 'store/types/typesPrompts';
import StyledIconButtonAsset from 'components/StyledWrappers/StyledIconButtonAsset';

const { TOOL_ENHANCE, TOOL_POLISH, IMAGE_TO_IMAGE } = generationToolsKeys;
const { sendTo, enhance, transform, generating, polish } = strings;
const { handleDataForGenerationToolStore } = commonUtils;
const { PROJECT_DRAWER_CONTROL_NET } = drawersKeys;

type Props = {
	generatedImage: GenerationImagePreview;
};

const BtnMoreConjure: React.FC<Props> = ({ generatedImage }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const dispatch = useStoreDispatch();
	const {
		isRequestingGenerationEnhance,
		isRequestingGenerationImageToImage,
		currentGenerationTool,
	} = useSliceOpenedProjects();
	const { data: controlNetToolsData } = useFetchControlNetToolsQuery();

	const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setIsMenuOpen(true);
	};

	const handleOnClose = () => {
		setAnchorEl(null);
		setIsMenuOpen(false);
	};

	const handleOnClickEnhance = () => {
		if (isRequestingGenerationEnhance) {
			return;
		}

		handleOnClose();

		const { imageId, imageUrl, imageWidth, imageHeight, generationData } = generatedImage;
		const { isLimitExceeded, message } = validateImageForSourceImage(
			imageWidth,
			imageHeight,
			TOOL_ENHANCE,
		);

		if (isLimitExceeded) {
			dispatch(
				showNotification({
					message,
					severity: 'warning',
				}),
			);
		} else {
			const handledData = handleDataForGenerationToolStore(TOOL_ENHANCE, generationData);

			dispatch(setGenerationTool(TOOL_ENHANCE));
			dispatch(
				setEnhanceSourceImageData({
					imageBase64: '',
					imageId,
					imageUrl,
					imageWidth,
					imageHeight,
				}),
			);
			dispatch(setIsGenerationContainerHidden(true));
			dispatch(setGenerationToolEnhanceSettings(handledData as EnhanceGenerationData));
		}
	};

	const handleOnClickTransform = async () => {
		if (isRequestingGenerationImageToImage) {
			return;
		}

		handleOnClose();

		const {
			imageId,
			imageUrl,
			imageWidth,
			imageHeight,
			type,
			isImageMatureContent,
			generationData,
		} = generatedImage;

		dispatch(setIsInPaintToolOn(false));
		dispatch(resetInPaintTool());

		dispatch(setGenerationTool(IMAGE_TO_IMAGE));
		dispatch(setIsGenerationContainerHidden(true));

		const handledData = handleDataForGenerationToolStore(IMAGE_TO_IMAGE, generationData);

		dispatch(
			setTransformSourceImageData({
				imageId,
				imageUrl,
				imageBase64: '',
				isImageMatureContent,
				imageWidth,
				imageHeight,
				type,
			}),
		);
		dispatch(setGenerationToolImageToImageSettings(handledData as ImageToImageGenerationData));
	};

	const handleOnClickPolish = () => {
		handleOnClose();

		const { imageUrl, imageWidth, imageHeight } = generatedImage;

		dispatch(setGenerationTool(TOOL_POLISH));
		dispatch(resetPolishTool());
		dispatch(
			setPolishSourceImageData({
				imageBase64: '',
				imageUrl,
				imageWidth,
				imageHeight,
			}),
		);
	};

	const handleClickControlNetTool = (controlNetKey: string) => {
		const { imageUrl, imageWidth, imageHeight, type } = generatedImage;

		handleOnClose();

		dispatch(setControlNetCurrentOpenedTool(controlNetKey));
		dispatch(openDrawerWithType(PROJECT_DRAWER_CONTROL_NET));
		dispatch(
			setControlNetSourceImage({
				sourceImageUrl: imageUrl,
				sourceImageBase64: '',
				type,
				imageWidth,
				imageHeight,
			}),
		);
	};

	return (
		<>
			<Tooltip title={sendTo} placement="top" arrow>
				<StyledIconButtonAsset
					onClick={(e) => handleOnClick(e)}
					disableRipple
					isFiledIcon
					hasBackground
				>
					<MoreVert fontSize="small" />
				</StyledIconButtonAsset>
			</Tooltip>

			{isMenuOpen && (
				<StyledMenu
					id="conjureMenuMore"
					open={isMenuOpen}
					anchorEl={anchorEl}
					onClose={handleOnClose}
					hasBorder
					anchorOrigin={{
						vertical: 50,
						horizontal: -62,
					}}
				>
					<StyledMenuItem
						padding="10px 16px"
						margin="0"
						onClick={handleOnClickTransform}
						disabled={isRequestingGenerationImageToImage}
					>
						<Tooltip
							title={isRequestingGenerationImageToImage ? generating : ''}
							placement="bottom"
							arrow
						>
							<Typography variant="h5" component="span">
								{transform}
							</Typography>
						</Tooltip>
					</StyledMenuItem>
					<StyledMenuItem
						padding="10px 16px"
						margin="0"
						onClick={handleOnClickEnhance}
						disabled={isRequestingGenerationEnhance}
					>
						<Tooltip
							title={isRequestingGenerationEnhance ? generating : ''}
							placement="bottom"
							arrow
						>
							<Typography variant="h5" component="span">
								{enhance}
							</Typography>
						</Tooltip>
					</StyledMenuItem>
					<StyledMenuItem
						padding="10px 16px"
						margin="0"
						divider
						onClick={handleOnClickPolish}
					>
						<Typography variant="h5" component="span">
							{polish}
						</Typography>
					</StyledMenuItem>
					{controlNetToolsData?.itemsByGenerationTool[currentGenerationTool].map(
						(key: string) => (
							<StyledMenuItem
								key={key}
								padding="10px 16px"
								margin="0"
								onClick={() => handleClickControlNetTool(key)}
								disabled={isRequestingGenerationEnhance}
							>
								{controlNetToolsData.items[key].name}
							</StyledMenuItem>
						),
					)}
				</StyledMenu>
			)}
		</>
	);
};

export default BtnMoreConjure;
