import React from 'react';
import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';

import { ReactComponent as ReportIcon } from 'assets/img/icons/report.svg';
import strings from 'constants/strings';
import externalUrls from 'constants/externalUrls';

const StyledButton = styled(Button)(({ theme }) => ({
	position: 'absolute',
	bottom: '-28px',
	left: '8px',
	padding: '0',
	textTransform: 'unset',

	svg: {
		path: {
			stroke: theme.palette.text.active,
			transition: '0.3s',
		},
	},

	'&:hover': {
		color: theme.palette.text.selected,

		svg: {
			path: {
				stroke: theme.palette.text.selected,
			},
		},
	},
}));

const { reportImage } = strings;
const { REPORT_IMAGE } = externalUrls;

const BtnReport: React.FC = () => {
	const handleOnClick = () => {
		window.open(REPORT_IMAGE, '_blank', 'noopener noreferrer');
	};

	return (
		<StyledButton variant="basic" onClick={handleOnClick} disableRipple>
			<ReportIcon width={17} height={17} style={{ marginRight: '2px' }} />
			<Typography variant="body1" component="span" sx={{ lineHeight: 1 }}>
				{reportImage}
			</Typography>
		</StyledButton>
	);
};

export default BtnReport;
