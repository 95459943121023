import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Box, Button, Typography, useTheme } from '@mui/material';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';
import useSliceModels from 'store/hooks/useSliceModels';
import useSliceSamplers from 'store/hooks/useSliceSamplers';
import { setModel, setSampler, setCfg, setClipSkip } from 'store/storeSlices/sliceOpenedProjects';
import { useFetchAdminTextsQuery } from 'store/apis/apiAdminTexts';
import { useFetchControlNetToolsQuery } from 'store/apis/apiControlNetTools';
import { textKeys, generationToolsKeys } from 'store/common/keys';
import { Model } from 'store/types/typesModels';

import { ReactComponent as StopIcon } from 'assets/img/icons/stop.svg';
import strings from 'constants/strings';
import useModelsAndAspectRatios from 'hooks/useModelsAndAspectRatios';

const StyledContainer = styled(Box)({
	position: 'absolute',
	zIndex: 3,
	width: '100%',
	minHeight: '320px',
	height: '100%',
	textAlign: 'center',
	padding: '80px 40px 40px',
	borderRadius: '4px',
});

const { UNAVAILABLE_CONTROL_NET_TOOL_DESCRIPTION } = textKeys;
const { switchTo } = strings;
const { TEXT_TO_IMAGE } = generationToolsKeys;

type Props = {
	isOverlay?: boolean;
};

const DrawerControlNetDisabledSourceOverlay: React.FC<Props> = ({ isOverlay = false }) => {
	const dispatch = useStoreDispatch();
	const theme = useTheme();

	const { currentControlNetOpenedTool, currentGenerationTool, currentRatio } =
		useSliceOpenedProjects();
	const { models } = useSliceModels();
	const sliceSamplers = useSliceSamplers();

	const { data: adminTextsData } = useFetchAdminTextsQuery();
	const { data: controlNetToolsData } = useFetchControlNetToolsQuery();
	const compatibleModelKey =
		controlNetToolsData?.items[currentControlNetOpenedTool]?.compatibleModelKey;

	const setPredefinedImageWidthAndHeight = useModelsAndAspectRatios();

	const [btnChangeModelLabel, setBtnChangeModelLabel] = useState<string>('');

	useEffect(() => {
		if (compatibleModelKey) {
			const compatibleModel: Model = models[currentGenerationTool]?.items.find(
				(element: Model) => element.key === compatibleModelKey,
			);

			if (compatibleModel) {
				setBtnChangeModelLabel(`${switchTo} ${compatibleModel.name}`);
			} else {
				setBtnChangeModelLabel('');
			}
		}
	}, [compatibleModelKey, currentGenerationTool, models]);

	const conditionalTitle = () => {
		if (adminTextsData?.[UNAVAILABLE_CONTROL_NET_TOOL_DESCRIPTION]) {
			return adminTextsData[UNAVAILABLE_CONTROL_NET_TOOL_DESCRIPTION];
		}

		return null;
	};

	const handleOnClickChangeModel = () => {
		if (compatibleModelKey) {
			const compatibleModel: Model = models[currentGenerationTool]?.items.find(
				(element: Model) => element.key === compatibleModelKey,
			);

			if (compatibleModel) {
				const { cfg, clipSkip, sampler: modelSampler } = compatibleModel;

				// Gets default sampler from the model or from the selected suite
				const sampler = modelSampler || sliceSamplers[currentGenerationTool].default;

				dispatch(setModel(compatibleModelKey));
				dispatch(setSampler(sampler));
				dispatch(setCfg(cfg));
				dispatch(setClipSkip(clipSkip));

				if (currentGenerationTool === TEXT_TO_IMAGE) {
					setPredefinedImageWidthAndHeight(compatibleModelKey, currentRatio);
				}
			}
		}
	};

	return (
		<StyledContainer
			data-testid="drawer-controlnet-disabled-source-overlay"
			sx={{
				backgroundColor: isOverlay
					? theme.palette.background.surfaceDark
					: theme.palette.background.surfaceLowest,
			}}
		>
			<StopIcon
				style={{
					width: '37px',
					height: '37px',
				}}
			/>

			<Typography
				variant="h6"
				sx={{
					color: theme.palette.text.primary,
					margin: '24px 0 28px',
				}}
			>
				{conditionalTitle()}
			</Typography>
			<Button
				variant="primary"
				onClick={handleOnClickChangeModel}
				sx={{
					fontSize: '13px',
					padding: '8px 16px',
				}}
			>
				{btnChangeModelLabel}
			</Button>
		</StyledContainer>
	);
};

export default DrawerControlNetDisabledSourceOverlay;
