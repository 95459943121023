import React from 'react';
import { Paper, Box } from '@mui/material';

type Props = {
	children: React.ReactNode;
};

const AuthFormWrapper: React.FC<Props> = ({ children }) => {
	return (
		<Box
			sx={{
				textAlign: 'center',
				gridRow: '2',
				margin: '15px',
			}}
		>
			<Paper
				sx={{
					// THEME_NEXT
					// background.paper | background.default makes it different
					// from the background image color
					background: '#0E0D0F',
					borderRadius: '16px',
					padding: 4,
					maxWidth: '470px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					margin: '0 auto',
					boxShadow: 'none',
				}}
			>
				{children}
			</Paper>
		</Box>
	);
};

export default AuthFormWrapper;
