import React, { DragEvent } from 'react';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';
import useSliceApp from 'store/hooks/useSliceApp';
import { closeDrawer } from 'store/storeSlices/sliceOpenedProjects';
import { resetImagesSlice } from 'store/storeSlices/sliceImages';
import { resetProjectImagesSlice } from 'store/storeSlices/sliceProjectImages';
import { drawersKeys } from 'store/common/keys';

import { ReactComponent as CloseIcon } from 'assets/img/icons/close.svg';
import strings from 'constants/strings';
import StyledDrawer from 'components/StyledWrappers/StyledDrawer';
import StyledIconButtonAsset from 'components/StyledWrappers/StyledIconButtonAsset';
import DrawerBodyLibrary from './DrawerBodyLibrary/DrawerBodyLibrary';
import DrawerBodyHistory from './DrawerBodyHistory/DrawerBodyHistory';
import DrawerBodyFavorites from './DrawerBodyFavorites/DrawerBodyFavorites';
import DrawerBodyEditLater from './DraweBodyEditLater/DrawerBodyEditLater';

const {
	PROJECT_DRAWER_LIBRARY,
	PROJECT_DRAWER_HISTORY,
	PROJECT_DRAWER_FAVORITES,
	PROJECT_DRAWER_EDIT_LATER,
} = drawersKeys;
const { library, projectHistory, favoritedImages, editLaterImages } = strings;

const StyledBox = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: '18px',
});

export const DrawerImages: React.FC = () => {
	const dispatch = useStoreDispatch();
	const { openedDrawer } = useSliceOpenedProjects();
	const { dragAndDrop } = useSliceApp();

	const { isDragging } = dragAndDrop;

	const handleOnDragOver = (e: DragEvent) => {
		const isOutsideTheDrawer = (e.target as Element).className.includes('MuiBackdrop-root');

		if (isDragging && isOutsideTheDrawer) {
			dispatch(closeDrawer());
		}
	};

	const handleOnClose = () => {
		switch (openedDrawer) {
			case PROJECT_DRAWER_HISTORY: {
				dispatch(resetProjectImagesSlice());
				break;
			}
			case PROJECT_DRAWER_LIBRARY:
			case PROJECT_DRAWER_FAVORITES:
			case PROJECT_DRAWER_EDIT_LATER:
			default: {
				dispatch(resetImagesSlice());
			}
		}

		dispatch(closeDrawer());
	};

	const conditionalContent = () => {
		switch (openedDrawer) {
			case PROJECT_DRAWER_LIBRARY:
				return {
					drawerTitle: library,
					container: <DrawerBodyLibrary />,
				};

			case PROJECT_DRAWER_HISTORY:
				return {
					drawerTitle: projectHistory,
					container: <DrawerBodyHistory />,
				};

			case PROJECT_DRAWER_FAVORITES:
				return {
					drawerTitle: favoritedImages,
					container: <DrawerBodyFavorites />,
				};

			case PROJECT_DRAWER_EDIT_LATER:
				return {
					drawerTitle: editLaterImages,
					container: <DrawerBodyEditLater />,
				};

			default:
				return {
					drawerTitle: '',
					container: null,
				};
		}
	};

	const conditionalDrawer = () => {
		switch (openedDrawer) {
			case PROJECT_DRAWER_HISTORY:
			case PROJECT_DRAWER_LIBRARY:
			case PROJECT_DRAWER_FAVORITES:
			case PROJECT_DRAWER_EDIT_LATER:
				return (
					<StyledDrawer
						data-testid="drawer-images"
						anchor="left"
						open={!!openedDrawer}
						onClose={handleOnClose}
						onDragOver={handleOnDragOver}
					>
						<StyledBox>
							<Typography variant="h4">{conditionalContent().drawerTitle}</Typography>

							<StyledIconButtonAsset onClick={handleOnClose} sx={{ padding: '0' }}>
								<CloseIcon />
							</StyledIconButtonAsset>
						</StyledBox>
						{conditionalContent().container}
					</StyledDrawer>
				);

			default:
				return null;
		}
	};

	return <>{conditionalDrawer()}</>;
};

export default DrawerImages;
