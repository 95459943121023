import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { MoreVert } from '@mui/icons-material';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';
import { generationToolsKeys, generationTypes } from 'store/common/keys';
import {
	setIsInPaintToolOn,
	setTransformSourceImageData,
	setGenerationTool,
	setIsGenerationContainerHidden,
	resetInPaintTool,
	setGenerationToolImageToImageSettings,
	setPolishSourceImageData,
	resetPolishTool,
} from 'store/storeSlices/sliceOpenedProjects';
import { ImageToImageGenerationData } from 'store/types/typesCommon';

import strings from 'constants/strings';

import StyledIconButtonMui from 'components/StyledWrappers/StyledIconButtonMui';
import StyledMenu from 'components/StyledWrappers/StyledMenu';
import StyledMenuItem from 'components/StyledWrappers/StyledMenuItem';
import commonUtils from 'store/common/utils';
import useGetImageGenerationDataAndTypeWithinProject from 'hooks/useGetImageGenerationDataAndTypeWithinProject';

const { sendTo, transform, generating, polish } = strings;
const { IMAGE_TO_IMAGE, TOOL_POLISH } = generationToolsKeys;
const { handleDataForGenerationToolStore } = commonUtils;

type Props = {
	hasBackground?: boolean;
	tooltipPlacement?: TooltipProps['placement'];
	generatedImage?: {
		imageId: string;
		imageUrl: string;
		imageWidth: number;
		imageHeight: number;
		type: (typeof generationTypes)[keyof typeof generationTypes];
	};
};

const BtnMoreEnhance: React.FC<Props> = ({
	hasBackground = false,
	tooltipPlacement = 'top',
	generatedImage = {
		imageId: '',
		imageUrl: '',
		imageWidth: 0,
		imageHeight: 0,
		type: null,
	},
}) => {
	const dispatch = useStoreDispatch();
	const { getImageGenerationDataAndType } = useGetImageGenerationDataAndTypeWithinProject();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const {
		currentEnhanceSourceImage,
		currentIsGenerationContainerHidden,
		isRequestingGenerationImageToImage,
	} = useSliceOpenedProjects();

	const {
		imageId: generatedImageId,
		imageUrl: generatedImageUrl,
		imageWidth: generatedImageWidth,
		imageHeight: generatedImageHeight,
		type: generatedImageType,
	} = generatedImage;

	const {
		imageId: sourceImageId,
		imageBase64: sourceImageBase64,
		imageUrl: sourceImageUrl,
		imageWidth: sourceImageWidth,
		imageHeight: sourceImageHeight,
	} = currentEnhanceSourceImage;

	const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setIsMenuOpen(true);
	};

	const handleOnClose = () => {
		setAnchorEl(null);
		setIsMenuOpen(false);
	};

	// TODO_ENHANCE Miro - refactor!!!
	const handleOnClickTransform = async () => {
		if (isRequestingGenerationImageToImage) {
			return;
		}

		handleOnClose();

		dispatch(setIsInPaintToolOn(false));
		dispatch(resetInPaintTool());

		if (currentIsGenerationContainerHidden) {
			dispatch(setGenerationTool(IMAGE_TO_IMAGE));
			dispatch(setIsGenerationContainerHidden(true));

			const { generationData, generationType } = await getImageGenerationDataAndType({
				imageId: sourceImageId,
			});

			// TODO_PETAR - console warning the Enhanced models are different than Transform models
			const handledData = handleDataForGenerationToolStore(IMAGE_TO_IMAGE, generationData);

			dispatch(
				setTransformSourceImageData({
					imageId: sourceImageId,
					imageUrl: sourceImageUrl,
					imageBase64: sourceImageBase64,
					isImageMatureContent: false,
					imageWidth: sourceImageWidth,
					imageHeight: sourceImageHeight,
					type: generationType,
				}),
			);
			dispatch(
				setGenerationToolImageToImageSettings(handledData as ImageToImageGenerationData),
			);
		} else {
			// TODO Miro - wire up with generated (enhanced) image
			if (generatedImageUrl) {
				dispatch(
					setTransformSourceImageData({
						imageId: generatedImageId,
						imageUrl: generatedImageUrl,
						imageBase64: '',
						isImageMatureContent: false,
						imageWidth: generatedImageWidth,
						imageHeight: generatedImageHeight,
						type: generatedImageType,
					}),
				);
			} else {
				dispatch(
					setTransformSourceImageData({
						imageId: sourceImageId,
						imageUrl: sourceImageUrl,
						imageBase64: sourceImageBase64,
						isImageMatureContent: false,
						imageWidth: sourceImageWidth,
						imageHeight: sourceImageHeight,
						type: null,
					}),
				);
			}

			const { generationData } = await getImageGenerationDataAndType({
				imageId: generatedImageId || sourceImageId,
			});

			const handledData = handleDataForGenerationToolStore(IMAGE_TO_IMAGE, generationData);

			dispatch(setGenerationTool(IMAGE_TO_IMAGE));
			dispatch(setIsGenerationContainerHidden(true));
			dispatch(
				setGenerationToolImageToImageSettings(handledData as ImageToImageGenerationData),
			);
		}
	};

	const handleOnClickPolish = async () => {
		handleOnClose();
		dispatch(setGenerationTool(TOOL_POLISH));
		dispatch(resetPolishTool());

		if (currentIsGenerationContainerHidden) {
			dispatch(
				setPolishSourceImageData({
					imageBase64: sourceImageBase64,
					imageUrl: sourceImageUrl,
					imageWidth: sourceImageWidth,
					imageHeight: sourceImageHeight,
				}),
			);
		} else if (generatedImageUrl) {
			dispatch(
				setPolishSourceImageData({
					imageBase64: '',
					imageUrl: generatedImageUrl,
					imageWidth: generatedImageWidth,
					imageHeight: generatedImageHeight,
				}),
			);
		} else {
			dispatch(
				setPolishSourceImageData({
					imageBase64: sourceImageBase64,
					imageUrl: sourceImageUrl,
					imageWidth: sourceImageWidth,
					imageHeight: sourceImageHeight,
				}),
			);
		}
	};

	return (
		<Box data-testid="btn-more-enhance">
			<Tooltip title={sendTo} placement={tooltipPlacement} arrow>
				<Box component="span" padding="0 0 10px">
					<StyledIconButtonMui
						onClick={handleOnClick}
						disableRipple
						hasBackground={hasBackground}
					>
						<MoreVert fontSize="small" />
					</StyledIconButtonMui>
				</Box>
			</Tooltip>

			{isMenuOpen && (
				<StyledMenu
					id="enhanceMenuMore"
					open={isMenuOpen}
					anchorEl={anchorEl}
					onClose={handleOnClose}
					anchorOrigin={{
						vertical: 45,
						horizontal: -55,
					}}
				>
					<StyledMenuItem padding="10px 16px" margin="0" onClick={handleOnClickPolish}>
						<Typography variant="h5" component="span">
							{polish}
						</Typography>
					</StyledMenuItem>
					<StyledMenuItem
						padding="10px 16px"
						margin="0"
						onClick={handleOnClickTransform}
						disabled={isRequestingGenerationImageToImage}
					>
						<Tooltip
							title={isRequestingGenerationImageToImage ? generating : ''}
							placement="bottom"
							arrow
						>
							<Typography variant="h5" component="span">
								{transform}
							</Typography>
						</Tooltip>
					</StyledMenuItem>
				</StyledMenu>
			)}
		</Box>
	);
};

export default BtnMoreEnhance;
