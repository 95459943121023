import React, { useState } from 'react';
import { Box, Tooltip } from '@mui/material';

import { ArgsImageMutation } from 'store/types/typesImages';

import { ReactComponent as DeleteIcon } from 'assets/img/icons/deleteIcon.svg';
import DialogDeleteImageFromModal from 'components/Dialogs/DialogDeleteImageFromModal';
import StyledIconButtonAsset from 'components/StyledWrappers/StyledIconButtonAsset';
import strings from 'constants/strings';

const { deleteImage } = strings;

type Props = {
	argsImageMutation: ArgsImageMutation;
};

const BtnDeleteImage: React.FC<Props> = ({ argsImageMutation }) => {
	const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);

	const handleOnClick = () => {
		setIsConfirmDeleteDialogOpen(true);
	};

	const closeConfirmDialog = () => {
		setIsConfirmDeleteDialogOpen(false);
	};

	return (
		<Box data-testid="btn-delete-image">
			{/* Temporary solution for:  */}
			{/* Blocked aria-hidden on a <button> element because the element that just received focus ...  */}
			{/* When DialogDeleteImageFromModal is refactored - check again */}
			{!isConfirmDeleteDialogOpen && (
				<Tooltip title={deleteImage} placement="top" arrow>
					<StyledIconButtonAsset
						onClick={handleOnClick}
						aria-label="Delete image button"
						disableRipple
					>
						<DeleteIcon />
					</StyledIconButtonAsset>
				</Tooltip>
			)}

			<DialogDeleteImageFromModal
				open={isConfirmDeleteDialogOpen}
				closeConfirmDialog={closeConfirmDialog}
				argsDeleteMutation={argsImageMutation}
			/>
		</Box>
	);
};

export default BtnDeleteImage;
