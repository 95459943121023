import { Typography, Box } from '@mui/material';
import InfoIconMui from 'components/Common/InfoIconMui';
import strings from 'constants/strings';
import React from 'react';
import { UserSubscription, UserSubscriptions } from 'store/types/typesUserWallets';
import { formatAmount } from 'utils/commonUtils';

type Props = {
	activeSubscription: UserSubscription | undefined;
	ongoingSubscriptions: UserSubscriptions;
};

const {
	credits,
	activeSubscriptionCreditsAmount,
	ongoingSubscriptionCreditsAmount,
	sumOfOngoingSubscriptionTooltip,
} = strings;

const SubscriptionPlanSummaryCredits: React.FC<Props> = ({
	activeSubscription,
	ongoingSubscriptions,
}) => {
	if (activeSubscription && ongoingSubscriptions.length === 0) {
		return (
			<Typography>
				{formatAmount(activeSubscription.creditsQuantity)}
				<Box component="span" textTransform="capitalize" ml="4px">
					{credits}
				</Box>
			</Typography>
		);
	}

	const ongoingCreditsAmount = ongoingSubscriptions.reduce((acc, subscription) => {
		return acc + subscription.creditsQuantity;
	}, 0);

	if (activeSubscription && ongoingSubscriptions.length > 0) {
		const totalAmount = ongoingCreditsAmount + activeSubscription.creditsQuantity;

		return (
			<Typography sx={{ display: 'flex', alignItems: 'center' }}>
				{formatAmount(totalAmount)}
				<Box component="span" textTransform="capitalize" ml="4px">
					{credits}
				</Box>
				<InfoIconMui
					content={
						<Box>
							<Box>
								{activeSubscriptionCreditsAmount}{' '}
								{formatAmount(activeSubscription.creditsQuantity)}
							</Box>
							<Box>
								{ongoingSubscriptionCreditsAmount}{' '}
								{formatAmount(ongoingCreditsAmount)}
							</Box>
						</Box>
					}
					boxSize="10px"
					margin="0 0 0 4px"
					iconSx={{
						color: 'text.textLowest',
						'&:hover': {
							color: 'text.hover',
						},
					}}
				/>
			</Typography>
		);
	}

	return (
		<Typography sx={{ display: 'flex', alignItems: 'center' }}>
			{formatAmount(ongoingCreditsAmount)}{' '}
			<Box component="span" textTransform="capitalize" ml="4px">
				{credits}
			</Box>
			{ongoingSubscriptions.length > 1 && (
				<InfoIconMui
					content={`${sumOfOngoingSubscriptionTooltip} ${ongoingSubscriptions.length}`}
					boxSize="8px"
					tooltipProps={{
						popper: {
							sx: { maxWidth: '190px' },
						},
					}}
					margin="0 0 0 4px"
					iconSx={{
						color: 'text.textLowest',
						'&:hover': {
							color: 'text.hover',
						},
					}}
				/>
			)}
		</Typography>
	);
};

export default SubscriptionPlanSummaryCredits;
