import React, { FormEvent, useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Box, CircularProgress } from '@mui/material';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { setArePaymentsDisabled } from 'store/storeSlices/slicePayments';
import { Product, PromoCodeDetails } from 'store/types/typesProducts';
import { useFetchActivePromoCodeQuery, useFetchProductInfoQuery } from 'store/apis/apiProducts';
import useSlicePayments from 'store/hooks/useSlicePayments';
import StripeCheckoutOrderSummary from './StripeCheckoutOrderSummary';
import StripeCheckoutOrderSummarySkeleton from './StripeCheckoutOrderSummarySkeleton';

type Props = {
	product: Product;
	totalPrice: string;
	promoCode?: PromoCodeDetails | null;
	setPaymentIntentId: React.Dispatch<React.SetStateAction<string>>;
};

const CheckoutForm: React.FC<Props> = ({ product, totalPrice, setPaymentIntentId }) => {
	const dispatch = useStoreDispatch();
	const stripe = useStripe();
	const elements = useElements();
	const { promoCode } = useSlicePayments();
	/** if there is no applied promocode when the form is opened, checks if user previously had applied one */
	const { isFetching: isFetchingActivePromoCode } = useFetchActivePromoCodeQuery(undefined, {
		skip: Boolean(promoCode),
	});
	const { data: productInfoData, isFetching: isFetchingProductInfo } = useFetchProductInfoQuery({
		productId: product.id,
	});

	const [isStripeLoading, setIsStripeLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [paymentProcessing, setPaymentProcessing] = useState(false);

	const isLoading = isFetchingProductInfo || isStripeLoading || isFetchingActivePromoCode;

	useEffect(() => {
		if (elements) {
			const element = elements.getElement('payment');
			element?.on('ready', () => {
				setIsStripeLoading(false);
			});
		}
	}, [elements]);

	const conditionalOrderSummary = () => {
		if (isLoading || !productInfoData) {
			return <StripeCheckoutOrderSummarySkeleton product={product} />;
		}

		return (
			<StripeCheckoutOrderSummary
				product={product}
				productInfo={productInfoData}
				totalPrice={totalPrice}
				errorMessage={errorMessage}
				paymentProcessing={paymentProcessing}
			/>
		);
	};

	const conditionalLoader = () => {
		if (!isLoading) return null;

		return (
			<Box
				sx={{
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					position: 'absolute',
					zIndex: 1,
				}}
			>
				<CircularProgress />
			</Box>
		);
	};

	const handleSubmit = async (event: FormEvent) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();
		if (!elements) return;

		if (!stripe) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setPaymentProcessing(true);
		dispatch(setArePaymentsDisabled(true));

		const { error, paymentIntent } = await stripe.confirmPayment({
			// `Elements` instance that was used to create the Payment Element
			elements,
			redirect: 'if_required',
			confirmParams: {
				return_url: window.location.href,
			},
		});

		if (error) {
			dispatch(setArePaymentsDisabled(false));
			setErrorMessage(error.message ?? '');
		}
		if (paymentIntent && paymentIntent.status === 'succeeded') {
			setPaymentIntentId(paymentIntent.id);
		}
		setPaymentProcessing(false);
	};

	return (
		<Box
			component="form"
			sx={{
				display: 'flex',
				padding: '12px 7px 24px',
				flexWrap: 'wrap',
				overflow: 'auto',
				width: '100%',
				minHeight: '100px',
				position: 'relative',
				'@media screen and (max-width: 777px)': {
					padding: '12px 0 24px',
				},
			}}
			data-rewardful
			data-testid="checkout-form"
			onSubmit={handleSubmit}
		>
			{conditionalLoader()}
			<Box flex="1 1 330px" padding="0 16px" mt="12px">
				<PaymentElement />
			</Box>
			<Box
				flex="1 1 330px"
				padding="0 16px"
				mt="12px"
				display="flex"
				justifyContent="space-between"
				flexDirection="column"
			>
				{conditionalOrderSummary()}
			</Box>
		</Box>
	);
};

export default CheckoutForm;
