import React, { useEffect } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Typography } from '@mui/material';
import strings from 'constants/strings';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { removePromoCode } from 'store/storeSlices/slicePayments';

const { paymentSuccessTitle, paymentSuccessMessage } = strings;

const CheckoutSuccessMessage: React.FC = () => {
	const dispatch = useStoreDispatch();

	useEffect(() => {
		// Cleans up promocode from the store when the component mounts
		dispatch(removePromoCode());
	}, [dispatch]);

	return (
		<Box
			display="flex"
			width="100%"
			alignItems="center"
			flexDirection="column"
			textAlign="center"
			padding="40px 30px 60px"
		>
			<CheckCircleOutlineIcon
				fontSize="large"
				sx={{
					fontSize: '50px',
					color: 'green',
				}}
			/>
			<Typography component="h2" variant="h2" mt="10px" mb="10px">
				{paymentSuccessTitle}
			</Typography>
			<Typography component="p" variant="h3" mb="10px">
				{paymentSuccessMessage}
			</Typography>
		</Box>
	);
};

export default CheckoutSuccessMessage;
