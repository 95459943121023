const externalUrls = {
	PRIVACY_POLICY: 'https://www.arcanalabs.ai/privacy-policy',
	TERMS_OF_SERVICE: 'https://www.arcanalabs.ai/terms-of-service',
	REPORT_IMAGE: 'https://help.arcanalabs.ai/learning-center/report-an-image',
	HELP: 'https://app.arcanalabs.ai/help',
	FAQ: 'https://www.arcanalabs.ai/faq',
	LEARNING_CENTER: 'https://help.arcanalabs.ai/learning-center',
	LEARNING_CENTER_MASK:
		'https://help.arcanalabs.ai/learning-center/introduction/inpaint-tools/mask-brush',
	LEARNING_CENTER_PAINT:
		'https://help.arcanalabs.ai/learning-center/introduction/inpaint-tools/paint-brush',
	LEARNING_CENTER_OUTPAINT:
		'https://help.arcanalabs.ai/learning-center/introduction/other-tools/zoom-out',
} as const;

export default externalUrls;
