import React from 'react';
import { GlobalStyles, useTheme } from '@mui/material';

const scrollbarBorderRadius = '17px';

const ArcanaGlobalStyles: React.FC = () => {
	const theme = useTheme();

	return (
		<GlobalStyles
			styles={{
				/* Apply Firefox-specific styles */
				'@supports (-moz-appearance:none)': {
					'html, body': {
						scrollbarWidth: 'thin',
						/* This color rgba(41, 41, 44, 1) is result of
						 ** background.surfaceAlternateLowest + background.surfaceLow + background.default
						 */
						scrollbarColor: `${theme.palette.text.secondary} rgba(41, 41, 44, 1)`,
					},
				},

				/* WebKit-specific scrollbar styling */
				'::-webkit-scrollbar': {
					width: '6px',
					height: '6px',
					WebkitTransform: 'scaleX(10)',
					borderRadius: scrollbarBorderRadius,
				},
				'::-webkit-scrollbar-track': {
					backgroundColor: theme.palette.background.surfaceAlternateLowest,
					borderRadius: scrollbarBorderRadius,
				},
				'::-webkit-scrollbar-corner': {
					background: 'none',
				},
				'::-webkit-scrollbar-thumb': {
					backgroundColor: 'rgba(255, 255, 255, 0.16)',
					border: 'none',
					boxShadow: 'none',
					borderRadius: scrollbarBorderRadius,
				},
				'::-webkit-scrollbar-thumb:hover': {
					backgroundColor: theme.palette.text.secondary,
				},
			}}
		/>
	);
};

export default ArcanaGlobalStyles;
