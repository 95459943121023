import React from 'react';

import strings from 'constants/strings';

import { Box, styled } from '@mui/material';

import { userSubscriptionStatusKeys } from 'store/common/keys';
import { UserSubscriptionStatusType } from 'store/types/typesUserWallets';

const { ACTIVE, ONGOING, FINISHED } = userSubscriptionStatusKeys;

const StyledBox = styled(Box)({
	display: 'inline-flex',
	alignItems: 'center',
	padding: '0 4px',
	fontSize: '11px',
	height: '12px',
	borderRadius: '6px',
});

const { subscriptionStatusActive, subscriptionStatusFinished, subscriptionStatusOngoing } = strings;

type Props = {
	status: UserSubscriptionStatusType;
};

const SubscriptionStatus: React.FC<Props> = ({ status }) => {
	switch (status) {
		case ACTIVE: {
			return (
				<StyledBox
					sx={{
						color: 'active.main',
						backgroundColor: 'active.dark',
					}}
				>
					{subscriptionStatusActive}
				</StyledBox>
			);
		}
		case ONGOING: {
			return (
				<StyledBox
					sx={{
						color: 'danger.main',
						backgroundColor: 'danger.dark',
					}}
				>
					{subscriptionStatusOngoing}
				</StyledBox>
			);
		}
		case FINISHED: {
			return (
				<StyledBox
					sx={{
						color: 'text.active',
						backgroundColor: 'background.surfaceHigh',
					}}
				>
					{subscriptionStatusFinished}
				</StyledBox>
			);
		}
		default:
			return null;
	}
};

export default SubscriptionStatus;
