import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';
import routesPaths from 'routes/paths';

import HeaderProjectTab from './HeaderProjectTab';
import HeaderNewProjectTabButton from './HeaderNewProjectTabButton';

const { MY_PROJECTS } = routesPaths;

const HeaderProjectTabs: React.FC = () => {
	const { pathname } = useLocation();
	const { projectId: openedProjectId } = useParams();
	const { openedProjectTabs } = useSliceOpenedProjects();

	/* Returns the project ID of the next or previous tab relative to the given index, or a default value if none exist */
	const getAdjacentProjectId = (tabIndex: number) => {
		const openedProjectIdOnClose = '';
		const nextOpenedProjectTab = openedProjectTabs[tabIndex + 1];
		const prevOpenedProjectTab = openedProjectTabs[tabIndex - 1];

		if (nextOpenedProjectTab) {
			return nextOpenedProjectTab.projectId;
		}

		if (prevOpenedProjectTab) {
			return prevOpenedProjectTab.projectId;
		}

		return openedProjectIdOnClose;
	};

	if (openedProjectTabs.length === 0) {
		return null;
	}

	return (
		<Box
			sx={{
				alignSelf: 'stretch',
				display: 'flex',
				alignItems: 'stretch',
				flex: '1 1 auto',
				minWidth: '0',
				margin: '0 8px',
				borderLeft: '1px solid',
				borderColor: 'background.surfaceHighest',
			}}
		>
			{openedProjectTabs.map((projectTab, index) => {
				const { currentGenerationTool, projectTitle, projectId } = projectTab;

				// TODO_NEXT find better name
				const openedProjectIdOnClose = getAdjacentProjectId(index);

				return (
					<HeaderProjectTab
						key={projectId}
						isActive={openedProjectId === projectId}
						projectId={projectId}
						currentGenerationTool={currentGenerationTool}
						projectTitle={projectTitle}
						openedProjectIdOnClose={openedProjectIdOnClose || ''}
					/>
				);
			})}
			{pathname !== MY_PROJECTS && <HeaderNewProjectTabButton />}
		</Box>
	);
};

export default HeaderProjectTabs;
