import React, { useState } from 'react';
import { Box, Fade, styled } from '@mui/material';

import { generationTypes, originFlowKeys } from 'store/common/keys';
import { ModelImage } from 'store/types/typesModels';
import { GenerationData } from 'store/types/typesCommon';

import { DIALOG_MODEL_ITEM_HEIGHT } from 'constants/default';
import useImageDimensions from 'hooks/useImageDimensions';
import BtnSendToConjure from 'components/Common/Buttons/BtnSendToConjure';
import BtnSendToTransform from 'components/Common/Buttons/BtnSendToTransform';

const InlineButtonsContainer = styled(Box)({
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '8px',
	bottom: '16px',
	width: '100%',
	zIndex: 1,
});

const Overlay = styled(Box)({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.68)',
	borderRadius: '4px',
	zIndex: 0, // Ensure overlay is behind the Buttons
	opacity: 1,
});

interface StyledImageBoxProps {
	image: string;
}

const StyledGridItem = styled(Box)<StyledImageBoxProps>(({ image }) => ({
	position: 'relative',
	width: '100%',
	height: `${DIALOG_MODEL_ITEM_HEIGHT}px`,
	backgroundImage: `url(${image}), linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))`,
	backgroundSize: 'contain',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	borderRadius: '4px',
	transition: 'background-color 0.3s ease',
	'&:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.68)',
		'& > div': {
			opacity: 1,
		},
	},
}));

const { ORIGIN_PROJECT_MODEL_DETAILED } = originFlowKeys;
const { CONJURE, TRANSFORM } = generationTypes;

type generationTypesKeys = keyof typeof generationTypes;

type Props = {
	item: ModelImage;
	collectGenerationData: (
		modelMetaImage: ModelImage,
		type: (typeof generationTypes)[generationTypesKeys],
	) => GenerationData;
};

const DialogModelsImageItem: React.FC<Props> = ({ item, collectGenerationData }) => {
	const { width: imageWidth, height: imageHeight } = useImageDimensions(item.url);
	const [isHovered, setHovered] = useState(false);

	const handleMouseEnter = () => {
		setHovered(true);
	};

	const handleMouseLeave = () => {
		setHovered(false);
	};

	return (
		<StyledGridItem
			image={item.url}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Fade in={isHovered}>
				<Box>
					<Overlay />
					<InlineButtonsContainer>
						<BtnSendToConjure
							isImageMatureContent={false}
							generationData={collectGenerationData(item, CONJURE)}
							origin={ORIGIN_PROJECT_MODEL_DETAILED}
						/>

						<BtnSendToTransform
							imageHeight={imageHeight}
							imageWidth={imageWidth}
							currentImage={{
								imageId: '',
								imageUrl: item.url,
								isImageMatureContent: false,
							}}
							hasIcon={false}
							generationData={collectGenerationData(item, TRANSFORM)}
							origin={ORIGIN_PROJECT_MODEL_DETAILED}
						/>
					</InlineButtonsContainer>
				</Box>
			</Fade>
		</StyledGridItem>
	);
};

export default DialogModelsImageItem;
