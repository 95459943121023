import {
	apiGenerationThreshold,
	useFetchGenerationThresholdQuery,
} from 'store/apis/apiGenerationThreshold';
import { generationToolsKeys } from 'store/common/keys';
import handleFetchGenerationThresholdData from 'store/dataHandlers/handleFetchGenerationThresholdData';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { ResponseGenerationThreshold } from 'store/types/typeGenerationThreshold';
import strings from 'constants/strings';
import commonUtils from 'store/common/utils';

const { generationTypeFromGenerationTool } = commonUtils;

const { suiteThresholdLimitReached, generalThresholdLimitReached } = strings;

const useManageGenerationThreshold = () => {
	const dispatch = useStoreDispatch();
	const { data: generationThresholdData, isFetching: isFetchingGenerationThreshold } =
		useFetchGenerationThresholdQuery();

	// Gets threshold tooltip
	const getGenerationLimitReachedTooltip = (
		generationTool: (typeof generationToolsKeys)[keyof typeof generationToolsKeys],
	) => {
		const tooltips = { generalThreshold: '', suiteThreshold: '' };
		// Should return empty string when threshold data is not fetched
		if (!generationThresholdData) return tooltips;

		const generalThreshold = generationThresholdData.GENERAL;

		if (generalThreshold.current >= generalThreshold.limit) {
			// Assigns general threshold limit reached tooltip
			tooltips.generalThreshold = generalThresholdLimitReached(generalThreshold.limit);
		}

		const currentThresholdForTool =
			generationThresholdData[generationTool as keyof typeof generalThreshold];

		// Gets the selected suite
		const suite = generationTypeFromGenerationTool(generationTool);

		if (currentThresholdForTool.current >= currentThresholdForTool.limit) {
			const suiteTooltip = suiteThresholdLimitReached(suite, currentThresholdForTool.limit);
			// Assigns suite threshold limit reached tooltip
			tooltips.suiteThreshold = suiteTooltip;
		}

		return tooltips;
	};

	// Decides if threshold limit is reached based on general threshold or suite threshold
	const getIsGenerationDisabledByThreshold = (
		generationTool: (typeof generationToolsKeys)[keyof typeof generationToolsKeys],
	) => {
		// Should disable buttons if threshold data is not fetched
		if (!generationThresholdData) return true;

		const generalThreshold = generationThresholdData.GENERAL;

		// Should disable buttons if general limit is reached
		if (generalThreshold.current >= generalThreshold.limit) return true;

		const currentThresholdForTool =
			generationThresholdData[generationTool as keyof typeof generalThreshold];

		// Should disable buttons related to the specific suite
		return currentThresholdForTool.current >= currentThresholdForTool.limit;
	};

	// Manually updates cached data - used in Pusher threshold event
	const updateGenerationThreshold = (
		responseGenerationThreshold: ResponseGenerationThreshold,
	) => {
		const generationThreshold = handleFetchGenerationThresholdData(responseGenerationThreshold);

		dispatch(
			apiGenerationThreshold.util.updateQueryData(
				'fetchGenerationThreshold',
				undefined, // cache key
				() => generationThreshold, // update query cache with the event data
			),
		);
	};

	return {
		updateGenerationThreshold,
		getIsGenerationDisabledByThreshold,
		getGenerationLimitReachedTooltip,
		isFetchingGenerationThreshold,
		hasGenerationThresholdData: Boolean(generationThresholdData),
	};
};

export default useManageGenerationThreshold;
