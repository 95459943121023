import React from 'react';
import { Box, Typography } from '@mui/material';

import { UserSubscription } from 'store/types/typesUserWallets';
import { creditProductsRedeemTypes } from 'store/common/keys';
import { formatDateToUSLocaleString, formatPrice } from 'utils/commonUtils';
import strings from 'constants/strings';

type Props = {
	activeSubscription: UserSubscription;
};

const { payment, nextRenewal, yearly } = strings;
const { ANNUALLY } = creditProductsRedeemTypes;

const SubscriptionPlanSummaryPayment: React.FC<Props> = ({ activeSubscription }) => {
	const { activeUntil, redeemType, price } = activeSubscription;

	const conditionalPlanTypeLabel = () => {
		if (redeemType === ANNUALLY) {
			return yearly;
		}
		return redeemType;
	};

	return (
		<Box display="flex" alignItems="center" justifyContent="space-between">
			<Box lineHeight="1">
				<Typography component="h5" variant="h5">
					{payment}
				</Typography>
				<Typography component="h6" variant="h6" color="text.active">
					{conditionalPlanTypeLabel()} &middot; {nextRenewal}{' '}
					{formatDateToUSLocaleString(activeUntil)}
				</Typography>
			</Box>
			<Typography>{formatPrice(price)}</Typography>
		</Box>
	);
};

export default SubscriptionPlanSummaryPayment;
