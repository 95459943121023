import { useLazyFetchImageQuery } from 'store/apis/apiImages';
import { useLazyFetchPromptQuery } from 'store/apis/apiPrompts';
import { generationTypes } from 'store/common/keys';
import commonUtils from 'store/common/utils';
import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { setIsOverlayLoaderOn } from 'store/storeSlices/sliceApp';
import { ImageItem } from 'store/types/typesImages';

const { PREPROCESS } = generationTypes;
const { emptyGenerationData, findGenerationByImageIdWithinHistory } = commonUtils;

const useGetImageGenerationDataAndTypeWithinProject = () => {
	const dispatch = useStoreDispatch();
	const { generationsHistory } = useSliceOpenedProjects();
	const [fetchSingleImage] = useLazyFetchImageQuery();
	const [fetchPrompt] = useLazyFetchPromptQuery();

	const getImageGenerationDataAndType = async ({
		imageId,
		shouldGetGenerationType = true,
	}: {
		imageId: string;
		shouldGetGenerationType?: boolean;
	}) => {
		// when imageId is empty string
		if (!imageId) {
			return {
				generationData: emptyGenerationData,
				generationType: null,
			};
		}

		const generation = findGenerationByImageIdWithinHistory(imageId, generationsHistory);
		let generationData = generation?.generationData || emptyGenerationData;

		const imageGenerationType: null | ImageItem['type'] = null;

		// !generationData.projectId means emptyGenerationsData
		// if emptyGenerationData and imageId then we need to fetch the prompt and get the generationData
		if (!generationData?.projectId && imageId) {
			dispatch(setIsOverlayLoaderOn(true));

			const image = await fetchSingleImage({
				imageId,
			}).unwrap();

			if (image.type !== PREPROCESS && image.promptId && shouldGetGenerationType) {
				generationData = await fetchPrompt({ promptId: image.promptId }).unwrap();
			}
			dispatch(setIsOverlayLoaderOn(false));
		}

		return {
			generationData,
			generationType: imageGenerationType,
		};
	};
	return { getImageGenerationDataAndType };
};

export default useGetImageGenerationDataAndTypeWithinProject;
