import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { showNotification } from 'store/storeSlices/sliceNotification';
import { notificationSeverity } from 'store/common/keys';

import strings from 'constants/strings';
import { copyToClipboard } from 'utils/commonUtils';
import StyledIconButtonMui from 'components/StyledWrappers/StyledIconButtonMui';

type Props = {
	prompt: string;
	disabled?: boolean;
};

const { success } = notificationSeverity;
const { copyPrompt, promptCopied } = strings;

const BtnCopyPrompt: React.FC<Props> = ({ prompt, disabled = false }) => {
	const dispatch = useStoreDispatch();

	const handleOnClick = () => {
		copyToClipboard(prompt);

		dispatch(
			showNotification({
				message: promptCopied,
				severity: success,
			}),
		);
	};

	const isButtonDisabled = disabled || !prompt;

	return (
		<Tooltip title={disabled ? '' : copyPrompt} placement="top" arrow>
			<Box>
				<StyledIconButtonMui
					onClick={handleOnClick}
					disabled={isButtonDisabled}
					disableRipple
				>
					<ContentCopy fontSize="small" />
				</StyledIconButtonMui>
			</Box>
		</Tooltip>
	);
};

export default BtnCopyPrompt;
