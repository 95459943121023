import React from 'react';
import { Box, styled, Button } from '@mui/material';
import { OutpaintOffset } from 'store/types/typesGeneration';
import { outpaintKeys } from 'store/common/keys';

const { UP, RIGHT, DOWN, LEFT } = outpaintKeys;

type LineProps = {
	isActive: boolean;
};

const positionAdjustmentPixels = 3;

const StyledYLine = styled(Box)({
	cursor: 'pointer',
	height: '36px',
	width: '2px',
});

const StyledXLine = styled(Box)({
	width: '36px',
	height: '2px',
	margin: '0 auto',
});

const StyledYLineBtn = styled(Button, {
	shouldForwardProp: (prop: string) => !['isActive'].includes(prop),
})<LineProps>(({ theme, isActive }) => ({
	padding: `0 ${positionAdjustmentPixels}px`,
	cursor: 'pointer',
	minWidth: '0',
	'.outpaint-control-line': {
		transition: '0.3s',
		backgroundColor: isActive
			? theme.palette.primary.main
			: theme.palette.background.surfaceLow,
	},
	'&:hover': {
		backgroundColor: 'transparent',
		'.outpaint-control-line': {
			backgroundColor: isActive ? theme.palette.primary.dark : theme.palette.text.textLowest,
		},
	},
}));

const StyledXLineBtn = styled(Button, {
	shouldForwardProp: (prop: string) => !['isActive'].includes(prop),
})<LineProps>(({ theme, isActive }) => ({
	padding: `${positionAdjustmentPixels}px 0`,
	cursor: 'pointer',
	minWidth: '0',
	'.outpaint-control-line': {
		transition: '0.3s',
		backgroundColor: isActive
			? theme.palette.primary.main
			: theme.palette.background.surfaceLow,
	},
	'&:hover': {
		backgroundColor: 'transparent',
		'.outpaint-control-line': {
			backgroundColor: isActive ? theme.palette.primary.dark : theme.palette.text.textLowest,
		},
	},
}));

type Props = {
	activeOffsetControls: {
		[key in OutpaintOffset]: boolean;
	};
	onChangeActiveOffsetControl: (controlType: OutpaintOffset) => () => void;
};

const OutpaintOffsetControls: React.FC<Props> = ({
	activeOffsetControls,
	onChangeActiveOffsetControl,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '44px',
				height: '44px',
				justifyContent: 'space-between',
			}}
			data-testid="outpaint-offset-controls"
		>
			<StyledXLineBtn
				isActive={activeOffsetControls[UP]}
				sx={{ marginTop: `-${positionAdjustmentPixels}px` }}
				onClick={onChangeActiveOffsetControl(UP)}
			>
				<StyledXLine className="outpaint-control-line" />
			</StyledXLineBtn>
			<Box
				sx={{
					display: 'flex',
					height: '36px',
					width: '100%',
					margin: '-1px auto',
					justifyContent: 'space-between',
				}}
			>
				<StyledYLineBtn
					isActive={activeOffsetControls[LEFT]}
					sx={{ marginLeft: `-${positionAdjustmentPixels}px` }}
					onClick={onChangeActiveOffsetControl(LEFT)}
				>
					<StyledYLine className="outpaint-control-line" />
				</StyledYLineBtn>

				<StyledYLineBtn
					isActive={activeOffsetControls[RIGHT]}
					sx={{ marginRight: `-${positionAdjustmentPixels}px` }}
					onClick={onChangeActiveOffsetControl(RIGHT)}
				>
					<StyledYLine className="outpaint-control-line" />
				</StyledYLineBtn>
			</Box>
			<StyledXLineBtn
				isActive={activeOffsetControls[DOWN]}
				sx={{ marginBottom: `-${positionAdjustmentPixels}px` }}
				onClick={onChangeActiveOffsetControl(DOWN)}
			>
				<StyledXLine className="outpaint-control-line" />
			</StyledXLineBtn>
		</Box>
	);
};

export default OutpaintOffsetControls;
