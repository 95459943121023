import { Box, Typography, Button } from '@mui/material';
import Spinner from 'components/Common/Spinner';
import PromoCodeElements from 'components/Subscriptions/PromoCodeElements';
import { subscriptionOptionsPanels } from 'components/Subscriptions/SubscriptionsOptionsForSubscribedUser';
import strings from 'constants/strings';
import useApplyPromoCode from 'hooks/useApplyPromoCode';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useInitiateBuyCreditsMutation, useLazyFetchProductsQuery } from 'store/apis/apiProducts';
import { creditProductsRedeemTypes, modalsKeys, walletKeys } from 'store/common/keys';
import useSlicePayments from 'store/hooks/useSlicePayments';
import useSliceUser from 'store/hooks/useSliceUser';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { openModal } from 'store/storeSlices/sliceApp';
import { PaymentIntent } from 'store/types/typesPayments';

const styles = {
	btn: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '11px',
		padding: '4px 16px',
	},
	buyCreditsBtn: {
		margin: '8px 0 5px',
	},
	btnBox: {
		padding: '8px 16px 16px',
	},
	btnBoxSubscribeNow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: '12px',
	},
};

const { buyCreditsButton, subscribeNow, dontWantToChangeTier, needMoreCredits, wantMoreCredits } =
	strings;
const { SUBSCRIPTIONS, CHECKOUT } = modalsKeys;
const { PAID } = walletKeys;
const { DEFAULT } = creditProductsRedeemTypes;
const { SUBSCRIPTIONS_PANEL } = subscriptionOptionsPanels;

const CreditsActionContent: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useStoreDispatch();
	const { applyPromoCode, promoCodeInputRef } = useApplyPromoCode();
	const { arePaymentsDisabled, isPromoCodeLoading } = useSlicePayments();
	const { hasActiveSubscription, wallets } = useSliceUser();
	const paidWalletBalance = wallets[PAID];

	const [
		checkoutCredits,
		{ data: buyCreditsData, isSuccess: isSuccessBuyCredits, isLoading: isLoadingBuyCredits },
	] = useInitiateBuyCreditsMutation();
	const [
		fetchProducts,
		{ data: productsData, isSuccess: isSuccessProducts, isLoading: isLoadingProducts },
	] = useLazyFetchProductsQuery();

	const isBuyCreditsBtnDisabled =
		arePaymentsDisabled || isLoadingBuyCredits || isLoadingProducts || isPromoCodeLoading;

	const handleBuyCredits = async () => {
		if (isBuyCreditsBtnDisabled) return;

		const { success } = await applyPromoCode();

		if (!success) return;

		fetchProducts();
		checkoutCredits();
	};

	const handleOpenSubscriptionsModal = () => {
		setSearchParams((params) => {
			params.append('panel', SUBSCRIPTIONS_PANEL);
			return params;
		});
		dispatch(openModal({ type: SUBSCRIPTIONS }));
	};

	const openCheckout = (paymentIntent: PaymentIntent) => {
		const defaultProduct = productsData?.find((product) => product.redeemType === DEFAULT);
		if (!defaultProduct) return;

		dispatch(
			openModal({
				type: CHECKOUT,
				data: {
					paymentIntent,
					product: defaultProduct,
				},
			}),
		);
	};

	useEffect(() => {
		if (isSuccessBuyCredits && isSuccessProducts && buyCreditsData) {
			openCheckout(buyCreditsData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessBuyCredits, isSuccessProducts, buyCreditsData]);

	const conditionalContent = () => {
		if (hasActiveSubscription) {
			return (
				<Box sx={styles.btnBox}>
					<Typography variant="body3" color="text.secondary">
						{paidWalletBalance === 0 ? wantMoreCredits : dontWantToChangeTier}
					</Typography>
					<Button
						disabled={isBuyCreditsBtnDisabled}
						variant="primary"
						type="button"
						sx={{ ...styles.btn, ...styles.buyCreditsBtn }}
						onClick={handleBuyCredits}
					>
						{buyCreditsButton}
						{isBuyCreditsBtnDisabled && (
							<Box>
								<Spinner size={8} margin="0 0 0 5px" />
							</Box>
						)}
					</Button>
					<PromoCodeElements
						inputRef={promoCodeInputRef}
						sx={{ margin: '0 0 -4px', '.MuiInputBase-root': { maxWidth: '130px' } }}
					/>
				</Box>
			);
		}

		return (
			<Box sx={{ ...styles.btnBox, ...styles.btnBoxSubscribeNow }}>
				<Typography variant="body3" color="text.secondary">
					{needMoreCredits}
				</Typography>
				<Button
					variant="primary"
					type="button"
					sx={styles.btn}
					onClick={handleOpenSubscriptionsModal}
				>
					{subscribeNow}
				</Button>
			</Box>
		);
	};

	return conditionalContent();
};

export default CreditsActionContent;
