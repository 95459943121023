import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { MoreVert } from '@mui/icons-material';

import strings from 'constants/strings';
import StyledIconButtonMui from 'components/StyledWrappers/StyledIconButtonMui';
import StyledMenu from 'components/StyledWrappers/StyledMenu';
import StyledMenuItem from 'components/StyledWrappers/StyledMenuItem';

import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { drawersKeys, generationToolsKeys } from 'store/common/keys';
import {
	openDrawerWithType,
	setControlNetCurrentOpenedTool,
	setControlNetSourceImage,
	setEnhanceSourceImageData,
	resetPolishTool,
	setPolishSourceImageData,
	setGenerationTool,
	setGenerationToolEnhanceSettings,
	setIsGenerationContainerHidden,
} from 'store/storeSlices/sliceOpenedProjects';
import { EnhanceGenerationData } from 'store/types/typesCommon';
import commonUtils from 'store/common/utils';
import { useFetchControlNetToolsQuery } from 'store/apis/apiControlNetTools';
import useGetImageGenerationDataAndTypeWithinProject from 'hooks/useGetImageGenerationDataAndTypeWithinProject';

const { TOOL_ENHANCE, TOOL_POLISH } = generationToolsKeys;
const { sendTo, enhance, generating, polish } = strings;
const { handleDataForGenerationToolStore } = commonUtils;
const { PROJECT_DRAWER_CONTROL_NET } = drawersKeys;

const BtnMoreTransform: React.FC = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const dispatch = useStoreDispatch();
	const { isRequestingGenerationEnhance, currentImageToImageSourceImage, currentGenerationTool } =
		useSliceOpenedProjects();
	const { data: controlNetToolsData } = useFetchControlNetToolsQuery();
	const { getImageGenerationDataAndType } = useGetImageGenerationDataAndTypeWithinProject();

	const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setIsMenuOpen(true);
	};

	const handleOnClose = () => {
		setAnchorEl(null);
		setIsMenuOpen(false);
	};

	// TODO consider optimizing code - no need of generationData check
	// const handledData = handleDataForGenerationToolStore(TOOL_ENHANCE, generationData || emptyGenerationData);
	const handleOnClickEnhance = async () => {
		if (isRequestingGenerationEnhance) {
			return;
		}

		handleOnClose();

		const { imageId, imageBase64, imageUrl, imageWidth, imageHeight } =
			currentImageToImageSourceImage;

		const { generationData } = await getImageGenerationDataAndType({
			imageId,
			shouldGetGenerationType: false,
		});

		dispatch(
			setEnhanceSourceImageData({
				imageId,
				imageUrl,
				imageBase64,
				imageWidth,
				imageHeight,
			}),
		);

		const handledData = handleDataForGenerationToolStore(TOOL_ENHANCE, generationData);

		dispatch(setGenerationTool(TOOL_ENHANCE));
		dispatch(setIsGenerationContainerHidden(true));
		dispatch(setGenerationToolEnhanceSettings(handledData as EnhanceGenerationData));
	};

	const handleOnClickPolish = () => {
		handleOnClose();

		const { imageBase64, imageUrl, imageWidth, imageHeight } = currentImageToImageSourceImage;

		dispatch(setGenerationTool(TOOL_POLISH));
		dispatch(resetPolishTool());
		dispatch(
			setPolishSourceImageData({
				imageBase64,
				imageUrl,
				imageWidth,
				imageHeight,
			}),
		);
	};

	const handleClickControlNetTool = (controlNetKey: string) => {
		const { imageBase64, imageUrl, imageWidth, imageHeight, type } =
			currentImageToImageSourceImage;

		handleOnClose();

		dispatch(setControlNetCurrentOpenedTool(controlNetKey));
		dispatch(openDrawerWithType(PROJECT_DRAWER_CONTROL_NET));
		dispatch(
			setControlNetSourceImage({
				sourceImageUrl: imageUrl,
				sourceImageBase64: imageBase64,
				type,
				imageWidth,
				imageHeight,
			}),
		);
	};

	return (
		<>
			<Tooltip title={sendTo} placement="top" arrow>
				<StyledIconButtonMui
					onClick={handleOnClick}
					disableRipple
					sx={{ padding: '0', height: '25px', scale: '1.1' }}
				>
					<MoreVert fontSize="small" />
				</StyledIconButtonMui>
			</Tooltip>

			{isMenuOpen && (
				<StyledMenu
					id="transformMenuMore"
					open={isMenuOpen}
					anchorEl={anchorEl}
					onClose={handleOnClose}
					hasBorder
					anchorOrigin={{
						vertical: 25,
						horizontal: -62,
					}}
				>
					<StyledMenuItem
						padding="10px 16px"
						margin="0"
						onClick={handleOnClickEnhance}
						disabled={isRequestingGenerationEnhance}
					>
						<Tooltip
							title={isRequestingGenerationEnhance ? generating : ''}
							placement="bottom"
							arrow
						>
							<Typography variant="h5" component="span">
								{enhance}
							</Typography>
						</Tooltip>
					</StyledMenuItem>
					<StyledMenuItem
						padding="10px 16px"
						margin="0"
						divider
						onClick={handleOnClickPolish}
					>
						<Typography variant="h5" component="span">
							{polish}
						</Typography>
					</StyledMenuItem>
					{controlNetToolsData?.itemsByGenerationTool[currentGenerationTool].map(
						(key: string) => (
							<StyledMenuItem
								key={key}
								padding="10px 16px"
								margin="0"
								onClick={() => handleClickControlNetTool(key)}
								disabled={isRequestingGenerationEnhance}
							>
								{controlNetToolsData.items[key].name}
							</StyledMenuItem>
						),
					)}
				</StyledMenu>
			)}
		</>
	);
};

export default BtnMoreTransform;
