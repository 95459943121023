import { useEffect, useRef } from 'react';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import useSliceApp from 'store/hooks/useSliceApp';
import { setAccentedGridImageItemId } from 'store/storeSlices/sliceApp';
import { ImageItem } from 'store/types/typesImages';

const useScrollToAccentedGridImage = () => {
	const dispatch = useStoreDispatch();
	const { accentedGridImageItemId } = useSliceApp();
	const accentedGridItemRef = useRef<HTMLDivElement | null>(null);

	const setAccentedGridItemRef = (item: ImageItem) => (element: HTMLDivElement) => {
		if (item.imageId === accentedGridImageItemId) {
			accentedGridItemRef.current = element;
		}
	};

	// Will clear the Store value when the component that uses this custom hook is unmounted (e.g. Drawer)
	useEffect(() => {
		return () => {
			dispatch(setAccentedGridImageItemId(''));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (accentedGridImageItemId && accentedGridItemRef.current) {
			// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
			accentedGridItemRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'nearest',
			});
		}
	}, [accentedGridImageItemId]);

	return {
		setAccentedGridItemRef,
	};
};

export default useScrollToAccentedGridImage;
