import React from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import LocalOffer from '@mui/icons-material/LocalOffer';

import { Product, ProductInfo } from 'store/types/typesProducts';
import useSlicePayments from 'store/hooks/useSlicePayments';
import { creditProductsRedeemTypes } from 'store/common/keys';

import strings from 'constants/strings';
import { ReactComponent as CoinIcon } from 'assets/img/icons/coin.svg';
import { ReactComponent as CoinSilverIcon } from 'assets/img/icons/coinSilver.svg';

const StyledSummaryBox = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '16px',
});

const {
	checkoutPayButton,
	checkoutFormProduct,
	checkoutFormTotal,
	checkoutOrderSummary,
	checkoutFormSubtotal,
} = strings;
const { DEFAULT } = creditProductsRedeemTypes;

type Props = {
	product: Product;
	totalPrice: string;
	errorMessage: string;
	productInfo: ProductInfo;
	paymentProcessing: boolean;
};

const StripeCheckoutOrderSummary: React.FC<Props> = ({
	product,
	totalPrice,
	productInfo,
	paymentProcessing,
	errorMessage,
}) => {
	const { promoCode } = useSlicePayments();
	const stripe = useStripe();

	const conditionalIcon = () => {
		// redeem type DEFAULT means Lifetime credits (not subscriptions)
		if (product.redeemType === DEFAULT) {
			return <CoinSilverIcon />;
		}

		return <CoinIcon />;
	};

	const conditionalContent = () => {
		if (promoCode) {
			return (
				<>
					<StyledSummaryBox>
						<Typography variant="h4">{checkoutFormSubtotal}</Typography>
						<Typography variant="h4" component="span" textAlign="right">
							{productInfo.amount}
						</Typography>
					</StyledSummaryBox>
					<StyledSummaryBox>
						<Typography
							variant="h4"
							sx={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}
						>
							<LocalOffer
								sx={{ color: 'active.main', fontSize: '14px', marginRight: '6px' }}
							/>
							{promoCode.code}
						</Typography>
						<Typography variant="h4" component="span" textAlign="right">
							-{promoCode.discountPercent}%
						</Typography>
					</StyledSummaryBox>
					<StyledSummaryBox
						sx={{
							paddingTop: '16px',
							borderTop: '1px solid',
							borderColor: 'text.disabled',
							marginTop: '28px',
						}}
					>
						<Typography variant="h4">{checkoutFormTotal}</Typography>
						<Typography variant="h4" component="span" textAlign="right">
							{totalPrice}
						</Typography>
					</StyledSummaryBox>
				</>
			);
		}

		return (
			<StyledSummaryBox
				sx={{
					paddingTop: '16px',
					borderTop: '1px solid',
					borderColor: 'text.disabled',
					marginTop: '28px',
				}}
			>
				<Typography variant="h4">{checkoutFormTotal}</Typography>
				<Typography variant="h4" component="span" textAlign="right">
					{totalPrice}
				</Typography>
			</StyledSummaryBox>
		);
	};

	return (
		<>
			<Box>
				<Typography
					variant="h3"
					sx={{
						padding: '21px 0',
						borderBottom: '1px solid',
						borderColor: 'text.disabled',
						marginBottom: '28px',
					}}
				>
					{checkoutOrderSummary}
				</Typography>

				<StyledSummaryBox>
					<Typography variant="h4">{checkoutFormProduct}</Typography>
					<Box display="flex" alignItems="center">
						{conditionalIcon()}
						<Typography
							variant="h4"
							sx={{
								display: 'flex',
								alignItems: 'center',
								marginLeft: '8px',
								lineHeight: '14px',
							}}
						>
							{product.name}
						</Typography>
					</Box>
				</StyledSummaryBox>
				{conditionalContent()}
			</Box>

			<Box marginTop="auto">
				{errorMessage && (
					<Box sx={{ color: 'error.main', marginTop: '12px', textAlign: 'center' }}>
						{errorMessage}
					</Box>
				)}
				<Box textAlign="right" mt="16px">
					<Button variant="primary" type="submit" disabled={!stripe || paymentProcessing}>
						{checkoutPayButton}
					</Button>
				</Box>
			</Box>
		</>
	);
};

export default StripeCheckoutOrderSummary;
