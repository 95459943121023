import React from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

import { ReactComponent as StopIcon } from 'assets/img/icons/stop.svg';

const StyledContainer = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	zIndex: 2,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '100%',
	borderRadius: '4px',
	backgroundColor: theme.palette.background.surfaceDarkLow,
}));

const IncompatibleModelOverlay: React.FC = () => {
	return (
		<StyledContainer>
			<StopIcon
				style={{
					width: '37px',
					height: '37px',
				}}
			/>
		</StyledContainer>
	);
};

export default IncompatibleModelOverlay;
