import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';

import strings from 'constants/strings';

import useSelectedStylesNames from 'hooks/useSelectedStylesNames';

import { useFetchControlNetToolsQuery } from 'store/apis/apiControlNetTools';
import { generationTypes, sharpnessKeys } from 'store/common/keys';
import useSliceModels from 'store/hooks/useSliceModels';
import useSliceEnhanceModels from 'store/hooks/useSliceEnhanceModels';
import { Model } from 'store/types/typesModels';
import commonUtils from 'store/common/utils';

import { ReactComponent as CopyIcon } from 'assets/img/icons/copy.svg';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { showNotification } from 'store/storeSlices/sliceNotification';
import InfoTooltipRowContent from './InfoTooltipRowContent';

const {
	modelInfo,
	stylesInfo,
	dimensions,
	optimizedForLabel,
	creativityStrength,
	transformation: transformationLabel,
	sharpnessLabel,
	generatedImageSuite,
	tool,
	influence,
	none,
	metadataCopiedSuccessfully,
	prompt: promptLabel,
} = strings;
const { CONJURE, TRANSFORM, INPAINT, UPSCALE, ENHANCE, PREPROCESS, PHOTIFY, OUTPAINT } =
	generationTypes;
const { generationToolFromGenerationType } = commonUtils;

type generationTypesKeys = keyof typeof generationTypes;
type KeysOfSharpness = keyof typeof sharpnessKeys;

type Props = {
	prompt: string;
	model: string;
	styles: Array<string>;
	transformation: number;
	sharpness: (typeof sharpnessKeys)[KeysOfSharpness];
	type: (typeof generationTypes)[generationTypesKeys];
	imageHeight: number;
	imageWidth: number;
	controlNetToolKey: string;
	controlNetToolInfluence: number;
};

type MetadataItem = { label: string; value: string | number };

const MetaDataTooltip: React.FC<Props> = ({
	prompt,
	model,
	styles,
	transformation,
	sharpness,
	type,
	imageHeight,
	imageWidth,
	controlNetToolKey,
	controlNetToolInfluence,
}) => {
	const dispatch = useStoreDispatch();
	const { models } = useSliceModels();
	const [modelName, setModelName] = useState<string>('');

	const enhanceModels = useSliceEnhanceModels();
	const [enhanceModelName, setEnhanceModelName] = useState<string>('');

	const imageGenerationTool = generationToolFromGenerationType(type);
	const { data: controlNetToolsData } = useFetchControlNetToolsQuery();
	const selectedStylesNames = useSelectedStylesNames(styles);
	const selectedStyles = selectedStylesNames || none;
	const controlNetToolName = controlNetToolsData?.items[controlNetToolKey]?.name;
	const hasInfluence = controlNetToolKey && controlNetToolInfluence >= 0;
	const imageDimensions = `${imageWidth} x ${imageHeight}`;

	// TODO_MIRO - consider to retrieve modelName from the slice without using useEffect
	useEffect(() => {
		const currentModel: Model = models[imageGenerationTool]?.items.find(
			(element) => element.key === model,
		);
		if (currentModel) {
			setModelName(currentModel.name);
		} else {
			setModelName('');
		}
	}, [models, imageGenerationTool, model]);

	// TODO_MIRO - consider to retrieve modelName from the slice without using useEffect
	useEffect(() => {
		const currentEnhanceModel = enhanceModels.find((element) => element.key === model);

		if (currentEnhanceModel) {
			setEnhanceModelName(currentEnhanceModel.value);
		} else {
			setEnhanceModelName('');
		}
	}, [enhanceModels, model]);

	const handleTransformationValue = () => {
		return `${Math.floor(transformation * 100)}%`;
	};

	const handleSharpness = () => {
		const sharpnessKey = Object.keys(sharpnessKeys).find(
			(key) => sharpnessKeys[key] === sharpness,
		);

		return sharpnessKey ? sharpnessKey.toLowerCase() : '';
	};

	// Formats metadata list to string
	// e.g. Prompt: "example prompt" | Model: Photo
	const metadataListToString = (metadataList: Array<MetadataItem>) => {
		return metadataList.reduce((acc, item) => {
			if (acc.length > 0) {
				return `${acc} | ${item.label}: ${item.value}`;
			}

			return `${item.label}: ${item.value}`;
		}, '');
	};

	// Retrieves the metadata label list based on the generation type.
	// The order in which the metadata is displayed in the tooltip or copied to the clipboard
	// depends on the order of the labels in the arrays.
	const getMetadataLabelsByType = () => {
		switch (type) {
			case CONJURE: {
				return [generatedImageSuite, modelInfo, stylesInfo, dimensions, tool, influence];
			}
			case UPSCALE: {
				return [generatedImageSuite, modelInfo, stylesInfo, dimensions];
			}
			case PHOTIFY: {
				return [generatedImageSuite, modelInfo, dimensions];
			}
			case OUTPAINT: {
				return [generatedImageSuite, dimensions];
			}
			case TRANSFORM:
			case INPAINT: {
				return [
					generatedImageSuite,
					modelInfo,
					transformationLabel,
					stylesInfo,
					dimensions,
					tool,
					influence,
				];
			}
			case ENHANCE: {
				return [
					generatedImageSuite,
					optimizedForLabel,
					creativityStrength,
					stylesInfo,
					sharpnessLabel,
					dimensions,
				];
			}
			case PREPROCESS: {
				return [tool];
			}
			default:
				return [];
		}
	};

	const getMetaDataItems = () => {
		// Defines all metadata fields
		const metadataList = [
			{ label: generatedImageSuite, value: type },
			modelName ? { label: modelInfo, value: modelName } : null,
			{ label: stylesInfo, value: selectedStyles },
			{ label: dimensions, value: imageDimensions },
			controlNetToolName ? { label: tool, value: controlNetToolName } : null,
			hasInfluence ? { label: influence, value: controlNetToolInfluence } : null,
			{ label: transformationLabel, value: handleTransformationValue() },
			{ label: optimizedForLabel, value: enhanceModelName },
			{ label: creativityStrength, value: transformation },
			{ label: sharpnessLabel, value: handleSharpness() },
		].filter((i) => i !== null) as Array<MetadataItem>;

		// Assigns metadata label list based on generation type
		const metadataLabelsByType = getMetadataLabelsByType();

		// Founds match between metadata label list and all metadata fields
		// returns partial list of metadataList
		return metadataLabelsByType.reduce(
			(acc, metadataLabel) => {
				const metadataPair = metadataList.find(
					(listItem) => listItem?.label === metadataLabel,
				);

				if (metadataPair) {
					acc.push(metadataPair);
				}
				return acc;
			},
			[] as typeof metadataList,
		);
	};

	// Gets metadata list so it can be used in copyToClipboard and in the rendered code
	const metadataItems = getMetaDataItems();

	const copyToClipboard = () => {
		// Creates Prompt metadata field
		const promptItem: MetadataItem | null =
			prompt.length > 0 ? { label: promptLabel, value: `"${prompt}"` } : null;

		// Decides if Prompt field should be added to metadata list if the field is truthy
		const extendedMetadataItems: Array<MetadataItem> = promptItem
			? [promptItem, ...metadataItems]
			: metadataItems;

		const metadataText = metadataListToString(extendedMetadataItems);

		navigator.clipboard.writeText(metadataText);

		dispatch(
			showNotification({
				message: metadataCopiedSuccessfully,
				severity: 'success',
			}),
		);
	};

	const conditionalContent = () => {
		if (metadataItems.length === 0) return null;

		return (
			<Box sx={{ display: 'flex' }}>
				<Box>
					{metadataItems.map((metadataItem) => {
						return (
							<InfoTooltipRowContent
								key={`row-${metadataItem.label}`}
								label={metadataItem.label}
								value={metadataItem.value}
							/>
						);
					})}
				</Box>
				<Box sx={{ paddingLeft: '10px' }}>
					<IconButton
						sx={{
							color: 'text.active',
							padding: '0',
							transition: '0.3s',
							'&:hover': {
								backgroundColor: 'unset',
								color: 'text.hover',
							},
						}}
						onClick={copyToClipboard}
					>
						<CopyIcon width="16px" height="16px" />
					</IconButton>
				</Box>
			</Box>
		);
	};

	return conditionalContent();
};

export default MetaDataTooltip;
