import React from 'react';
import { Box } from '@mui/material';
import InputModalModels from 'components/Common/InputModalModels';
import StyledIconButtonAsset from 'components/StyledWrappers/StyledIconButtonAsset';

import { ReactComponent as CloseIcon } from 'assets/img/icons/close.svg';

type Props = {
	selectedModelKey: string;
	isFetchingContent: boolean;
	onSelectModel: (value: string) => void;
	handleCloseModal: () => void;
};

const DialogModelsHeader: React.FC<Props> = ({
	isFetchingContent,
	selectedModelKey,
	onSelectModel,
	handleCloseModal,
}) => {
	return (
		<Box display="flex" justifyContent="center" padding="12px 24px">
			<Box width="100%" maxWidth="236px" ml="auto" pl="36px">
				<InputModalModels
					isDisabled={isFetchingContent}
					defaultModelKey={selectedModelKey}
					onSelect={onSelectModel}
				/>
			</Box>
			<StyledIconButtonAsset
				onClick={handleCloseModal}
				disableRipple
				sx={{ padding: '0', ml: 'auto', svg: { scale: '1.1' } }}
			>
				<CloseIcon />
			</StyledIconButtonAsset>
		</Box>
	);
};

export default DialogModelsHeader;
