import { useRef } from 'react';
import {
	useActivatePromoCodeMutation,
	useDeactivatePromoCodeMutation,
	useValidatePromoCodeForDefaultProductMutation,
	useValidatePromoCodeForProductMutation,
	useVerifyPromoCodeMutation,
} from 'store/apis/apiProducts';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { setIsPromoCodeLoading, setPromoCode } from 'store/storeSlices/slicePayments';

const useApplyPromoCode = () => {
	const dispatch = useStoreDispatch();
	const [activatePromoCode] = useActivatePromoCodeMutation();
	const [verifyPromoCode] = useVerifyPromoCodeMutation();
	const [validateCodeForSpecificProduct] = useValidatePromoCodeForProductMutation();
	const [validateCodeForDefaultProduct] = useValidatePromoCodeForDefaultProductMutation();
	const [deactivatePromoCode] = useDeactivatePromoCodeMutation();
	const promoCodeInputRef = useRef<HTMLInputElement | null>(null);

	const applyPromoCode = async (productId?: number) => {
		const inputValue = promoCodeInputRef.current?.value;

		const handleFailedRequest = () => {
			dispatch(setIsPromoCodeLoading(false));
			return { success: false };
		};

		// Checks if the input is "dirty"
		if (inputValue && inputValue.trim().length > 0) {
			const codeValue = inputValue.trim();
			dispatch(setIsPromoCodeLoading(true));

			// Decides which mutation should be used based on the `productId` parameter
			const validateCodeForProduct =
				typeof productId === 'number'
					? validateCodeForSpecificProduct({
							code: inputValue.trim(),
							productId,
						})
					: validateCodeForDefaultProduct({ code: codeValue });

			const { data, error } = await validateCodeForProduct;

			// If there is an error or BE returns that the code is not suitable
			if (error || (data && !data.success)) {
				return handleFailedRequest();
			}

			// calls validate and active simultaniously
			const [verifyRes, activateRes] = await Promise.all([
				verifyPromoCode({ code: codeValue }),
				activatePromoCode({ code: codeValue }),
			]);

			// if BE successfully activates promocode but the validate request failed
			// to return the existing Promocode, the code should be deactivated
			// NOTE: This should be edge case
			if (activateRes?.data?.success && verifyRes.error) {
				await deactivatePromoCode();
			}

			// if one of the requests fails
			if (verifyRes.error || !verifyRes.data || activateRes.error) {
				return handleFailedRequest();
			}

			dispatch(setPromoCode(verifyRes.data));
		}

		dispatch(setIsPromoCodeLoading(false));

		// If no value in the input or everything passed successfully
		return { success: true };
	};

	return {
		applyPromoCode,
		promoCodeInputRef,
	};
};

export default useApplyPromoCode;
