import React from 'react';
import { Box, Button, Tooltip } from '@mui/material';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';
import { useFetchControlNetToolsQuery } from 'store/apis/apiControlNetTools';
import { closeModal } from 'store/storeSlices/sliceApp';
import { setModel } from 'store/storeSlices/sliceOpenedProjects';
import { Model } from 'store/types/typesModels';

import { allModelsModel } from 'constants/default';
import strings from 'constants/strings';

const { cancel, gotItThanks, unavailableWith } = strings;

type Props = {
	isFetchingContent: boolean;
	selectedModel: Model;
};

const { key: allModelsKey } = allModelsModel;

const DialogModelsFooter: React.FC<Props> = ({ isFetchingContent, selectedModel }) => {
	const dispatch = useStoreDispatch();

	const { currentControlNetActiveTool } = useSliceOpenedProjects();
	const { data: controlNetToolsData } = useFetchControlNetToolsQuery();
	const controlNetToolName = controlNetToolsData?.items[currentControlNetActiveTool]?.name;

	const {
		key: modelKey,
		name: modelName,
		isCompatibleWithControlNet: isModelCompatibleWithControlNet,
	} = selectedModel;
	const isAllModelsSelected = modelKey === allModelsKey;
	const saveButtonText = isAllModelsSelected ? gotItThanks : `Use ${modelName} model`;

	const handleCloseModal = () => {
		dispatch(closeModal());
	};

	const handleSave = () => {
		if (isFetchingContent) return;
		if (!isAllModelsSelected) {
			dispatch(setModel(modelKey));
		}
		handleCloseModal();
	};

	const conditionalSaveBtnTooltip = () => {
		// if All models are selected
		if (isAllModelsSelected) return null;

		// if there is an active ControlNet tool
		if (currentControlNetActiveTool && !isModelCompatibleWithControlNet) {
			return `${unavailableWith} ${controlNetToolName}`;
		}

		return null;
	};

	// saveBtn is disabled when isFetchingContent OR
	// a ControlNet tool is active but the selectedModel is not compatible with ControlNet
	const isSaveBtnDisabled =
		isFetchingContent || (!!currentControlNetActiveTool && !isModelCompatibleWithControlNet);

	return (
		<Box display="flex" gap="10px" padding="16px 24px">
			<Button type="button" variant="flat" onClick={handleCloseModal}>
				{cancel}
			</Button>
			<Tooltip title={conditionalSaveBtnTooltip()} placement="top" arrow>
				<Box>
					<Button
						disabled={isSaveBtnDisabled}
						type="button"
						variant="primary"
						onClick={handleSave}
					>
						{saveButtonText}
					</Button>
				</Box>
			</Tooltip>
		</Box>
	);
};

export default DialogModelsFooter;
