import { Link, Typography } from '@mui/material';
import strings from 'constants/strings';
import React from 'react';

type Props = {
	title: string;
	linkText: string;
	handleLinkClick: (event: React.MouseEvent) => void;
};

const AuthFormHeader: React.FC<Props> = ({ title, linkText, handleLinkClick }) => {
	return (
		<>
			<Typography
				component="h1"
				mb={0}
				sx={{
					fontSize: '20px',
				}}
			>
				{title}
			</Typography>

			<Typography
				sx={{
					fontSize: '16px',
				}}
				variant="caption"
			>
				{strings.orText}
				<Link
					sx={{
						marginLeft: '4px',
						marginTop: '-2px',
						fontWeight: '600',
						transition: '0.3s',
						'&:hover': {
							color: 'primary.dark',
						},
					}}
					component="button"
					variant="body2"
					fontSize="inherit"
					underline="none"
					onClick={handleLinkClick}
				>
					{linkText}
				</Link>
			</Typography>
		</>
	);
};

export default AuthFormHeader;
