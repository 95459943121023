import React, { useEffect } from 'react';

import { Box, Button, Link, Skeleton, Typography } from '@mui/material';
import StyledDialogContent from 'components/StyledWrappers/StyledDialogContent';
import StyledDialogHorizontalCard from 'components/StyledWrappers/StyledDialogHorizontalCard';
import StyledDialogHeader from 'components/StyledWrappers/StyledDialogHeader';
import StyledIconButtonAsset from 'components/StyledWrappers/StyledIconButtonAsset';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import useSliceUser from 'store/hooks/useSliceUser';
import { showNotification } from 'store/storeSlices/sliceNotification';
import { closeModal } from 'store/storeSlices/sliceApp';
import { textKeys } from 'store/common/keys';
import { useFetchAdminTextsQuery } from 'store/apis/apiAdminTexts';
import { useRedeemUserCreditsMutation } from 'store/apis/apiUserWallets';

import modalImage from 'assets/img/redeem_credits_image.png';
import { ReactComponent as CloseIcon } from 'assets/img/icons/close.svg';
import { ReactComponent as CoinsIcon } from 'assets/img/icons/golden_coins.svg';
import strings from 'constants/strings';
import externalUrls from 'constants/externalUrls';

const { FREE_CREDITS_MODAL_DESCRIPTION, FREE_CREDITS_MODAL_TITLE, FREE_CREDITS_MODAL_DISCLAIMER } =
	textKeys;

const {
	successCreditsRedeem,
	errorCreditsRedeem,
	contactUs,
	freeCreditsRedeemBtn,
	freeCreditsModalTitle,
	freeCreditsModalContactUs,
	freeCreditsRedeemedBtn,
} = strings;

const { HELP } = externalUrls;

const styles = {
	image: {
		display: 'block',
		width: '100%',
		height: '100%',
		objectFit: 'cover' as 'cover',
		borderTopLeftRadius: '8px',
		borderBottomLeftRadius: '8px',
	},
};

const DialogRedeemCredits: React.FC = () => {
	const dispatch = useStoreDispatch();
	const { creditsRedeem } = useSliceUser();
	const [updateUserWalletsRedeem, { isSuccess, isLoading, isError }] =
		useRedeemUserCreditsMutation();

	const successCreditsRedeemText = successCreditsRedeem.replace(
		':creditsRedeem',
		creditsRedeem.toString(),
	);
	const redeemBtnText = freeCreditsRedeemBtn.replace(':creditsRedeem', creditsRedeem.toString());
	const { isLoading: isFetchingAdminTexts, data: adminTextsData } = useFetchAdminTextsQuery();
	const isRedeemBtnDisabled = isLoading || isSuccess || !creditsRedeem;

	const escapeHtmlExceptBr = (text: string) => {
		// Escape all HTML tags except <br/>
		return text.replace(/<\/?(?!br\b)[a-zA-Z][^\s>]*[^>]*>/g, (match) => {
			return match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
		});
	};

	const conditionalTitle = () => {
		const dialogTitle = adminTextsData?.[FREE_CREDITS_MODAL_TITLE];

		if (isFetchingAdminTexts) {
			return (
				<>
					<Skeleton height="28px" width="100%" />
					<Skeleton height="28px" width="100%" sx={{ marginBottom: '16px' }} />
				</>
			);
		}

		if (!dialogTitle) return null;

		return (
			<Typography
				variant="h2"
				component="h3"
				mb="16px"
				dangerouslySetInnerHTML={{ __html: escapeHtmlExceptBr(dialogTitle) }}
			/>
		);
	};

	const conditionalDescription = () => {
		const dialogDescription = adminTextsData?.[FREE_CREDITS_MODAL_DESCRIPTION];

		if (isFetchingAdminTexts) {
			return (
				<>
					<Skeleton height="14px" width="100%" sx={{ marginBottom: '6px' }} />
					<Skeleton height="14px" width="100%" sx={{ marginBottom: '6px' }} />
					<Skeleton height="14px" width="100%" sx={{ marginBottom: '6px' }} />
					<Skeleton height="14px" width="100%" sx={{ marginBottom: '60px' }} />
				</>
			);
		}

		if (!dialogDescription) return null;

		return (
			<Typography variant="body1" component="p" mb="66px">
				{escapeHtmlExceptBr(dialogDescription)}
			</Typography>
		);
	};

	const conditionalDisclaimers = () => {
		const disclaimer = adminTextsData?.[FREE_CREDITS_MODAL_DISCLAIMER];

		if (isFetchingAdminTexts) {
			return (
				<>
					<Skeleton height="14px" width="100%" sx={{ marginBottom: '4px' }} />
					<Skeleton height="14px" width="100%" sx={{ marginBottom: '4px' }} />
					<Skeleton height="14px" width="100%" sx={{ marginBottom: '4px' }} />
					<Skeleton height="14px" width="100%" sx={{ marginBottom: '24px' }} />
				</>
			);
		}

		if (!disclaimer) return null;

		return (
			<Typography
				variant="body2"
				component="p"
				color="text.active"
				mb="24px"
				dangerouslySetInnerHTML={{
					__html: escapeHtmlExceptBr(disclaimer),
				}}
			/>
		);
	};

	const conditionalRedeemBtnText = () => {
		if (isSuccess || !creditsRedeem) return freeCreditsRedeemedBtn;

		return redeemBtnText;
	};

	const handleOnClose = () => {
		dispatch(closeModal());
	};

	useEffect(() => {
		if (isError) {
			dispatch(
				showNotification({
					message: errorCreditsRedeem,
					severity: 'warning',
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				showNotification({
					message: successCreditsRedeemText,
					severity: 'success',
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	const handleRedeem = () => {
		if (isRedeemBtnDisabled) return;

		updateUserWalletsRedeem();
	};

	return (
		<StyledDialogHorizontalCard
			open
			onClose={handleOnClose}
			aria-labelledby="redeem-credits-dialog-title"
			aria-describedby="redeem-credits-dialog-description"
			width="800px"
		>
			<StyledDialogContent>
				<Box sx={{ display: 'flex' }}>
					<Box sx={{ width: '50%' }}>
						<img src={modalImage} alt="credits" style={styles.image} />
					</Box>
					<Box sx={{ width: '50%' }}>
						<StyledDialogHeader hasBorderBottom padding="12px 24px">
							<Typography variant="h4" component="h4" color="text.primary">
								{freeCreditsModalTitle}
							</Typography>
							<StyledIconButtonAsset
								onClick={handleOnClose}
								sx={{ padding: 0, marginLeft: 'auto' }}
							>
								<CloseIcon />
							</StyledIconButtonAsset>
						</StyledDialogHeader>
						<Box padding="24px">
							<CoinsIcon
								width="68px"
								height="68px"
								style={{ marginBottom: '16px' }}
							/>
							{conditionalTitle()}
							{conditionalDescription()}
							<Button
								variant="primary"
								onClick={handleRedeem}
								disabled={isRedeemBtnDisabled}
								sx={{ marginBottom: '24px' }}
							>
								{conditionalRedeemBtnText()}
							</Button>
							{conditionalDisclaimers()}
							<Typography variant="body2" component="p" color="text.active">
								{`${freeCreditsModalContactUs} `}
								<Link
									href={HELP}
									sx={{
										color: 'text.active',
										transition: '0.3s',
										'&:hover': {
											color: 'text.hover',
										},
									}}
									target="_blank"
									rel="noopener noreferrer"
								>
									{contactUs}
								</Link>
							</Typography>
						</Box>
					</Box>
				</Box>
			</StyledDialogContent>
		</StyledDialogHorizontalCard>
	);
};

export default DialogRedeemCredits;
