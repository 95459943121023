import React from 'react';
import { Box, Button, DialogActions, FormHelperText, Link, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import StyledDialog from 'components/StyledWrappers/StyledDialog';
import StyledIconButtonAsset from 'components/StyledWrappers/StyledIconButtonAsset';
import StyledDeskopDatepicker, {
	popperStyles,
} from 'components/StyledWrappers/StyledDeskopDatepicker';
import Spinner from 'components/Common/Spinner';

import { useUpdateNsfwMutation } from 'store/apis/apiUser';
import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { closeModal } from 'store/storeSlices/sliceApp';

import { ReactComponent as CloseIcon } from 'assets/img/icons/close.svg';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/calendar.svg';

import strings from 'constants/strings';
import {
	MATURE_CONTENT_ALLOW_AGE,
	DATEPICKER_INPUT_FORMAT,
	DATEPICKER_REQUEST_FORMAT,
} from 'constants/default';
import routePaths from 'routes/paths';

const { TERMS_OF_SERVICE } = routePaths;

const {
	dialogMatureContentSubmit,
	invalidDate,
	matureContentTerms,
	dialogMatureContentTitle,
	dialogMatureContentDatepickerLabel,
	matureContentAgeError,
	termsOfService,
} = strings;

const DialogMatureContent: React.FC = () => {
	const dispatch = useStoreDispatch();
	const [updateUser, { isLoading: isUpdatingUser }] = useUpdateNsfwMutation();

	const validationSchema = Yup.object().shape({
		ageVerified: Yup.string().required('Date is required'),
	});

	const onConfirm = async ({ ageVerified }: { ageVerified: string }) => {
		const minMatureContentAllowedDate = moment().subtract(MATURE_CONTENT_ALLOW_AGE, 'years');
		const isDateValid = moment(ageVerified, DATEPICKER_INPUT_FORMAT, true).isValid();

		if (!isDateValid) {
			formik.setFieldError('ageVerified', invalidDate);
			return;
		}

		const isOlderEnough = moment(ageVerified, DATEPICKER_INPUT_FORMAT).isSameOrBefore(
			minMatureContentAllowedDate,
			'day',
		);

		if (!isOlderEnough) {
			formik.setFieldError('ageVerified', matureContentAgeError);
			return;
		}

		const date = moment().format(DATEPICKER_REQUEST_FORMAT);

		await updateUser({
			age_verified: date,
			nsfw: false,
		});

		dispatch(closeModal());
	};

	const handleCloseClick = () => {
		dispatch(closeModal());
	};

	const formik = useFormik({
		initialValues: {
			ageVerified: '',
		},
		validateOnChange: false,
		validationSchema,
		onSubmit: onConfirm,
	});

	const handleDateChange = (momentDate: moment.Moment | null) => {
		formik.setFieldValue('ageVerified', momentDate?.format(DATEPICKER_INPUT_FORMAT));
		// Resets error for 'ageVerified' field
		formik.setFieldError('ageVerified', undefined);
	};

	const isVerifyBtnDisabled =
		!formik.values.ageVerified || !!formik.errors.ageVerified || isUpdatingUser;

	return (
		<StyledDialog open onClose={handleCloseClick} height="auto" width="452px">
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography variant="h3" component="h3">
					{dialogMatureContentTitle}
				</Typography>
				<StyledIconButtonAsset
					disabled={isUpdatingUser}
					onClick={handleCloseClick}
					sx={{ padding: '0' }}
				>
					<CloseIcon />
				</StyledIconButtonAsset>
			</Box>
			<Box
				component="form"
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'center',
					textAlign: 'center',
					width: '220px',
					margin: '36px auto 0',
				}}
				onSubmit={formik.handleSubmit}
			>
				<Typography variant="h3" component="h3" mb="26px">
					{dialogMatureContentDatepickerLabel}
				</Typography>

				<Box margin="0 0 24px" position="relative">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<StyledDeskopDatepicker
							sx={{
								'& .MuiInputBase-input': {
									padding: '10px 0 10px 8px',
								},
							}}
							slots={{ openPickerIcon: CalendarIcon }}
							slotProps={{
								popper: { sx: popperStyles, placement: 'bottom-start' },
							}}
							format={DATEPICKER_INPUT_FORMAT}
							onChange={handleDateChange}
						/>
					</LocalizationProvider>
					{formik.errors.ageVerified && (
						<FormHelperText
							component="div"
							sx={{
								color: 'danger.main',
								position: 'absolute',
								top: 'calc(100% + 1px)',
								width: '100%',
								textAlign: 'center',
							}}
						>
							<Typography variant="body2">{formik.errors.ageVerified}</Typography>
						</FormHelperText>
					)}
				</Box>
				<DialogActions sx={{ padding: 0, width: '100%' }}>
					<Button
						variant="primary"
						disabled={isVerifyBtnDisabled}
						type="submit"
						sx={{ padding: '10px 16px', lineHeight: '1.25', width: '100%' }}
					>
						{dialogMatureContentSubmit}
						{isUpdatingUser && (
							<Box>
								<Spinner size={16} margin="0 0 0 5px" />
							</Box>
						)}
					</Button>
				</DialogActions>
			</Box>
			<Typography textAlign="center" variant="body3" mt="16px" color="text.active">
				{`${matureContentTerms} `}
				<Link
					color="text.active"
					variant="body2"
					fontSize="inherit"
					underline="none"
					href={TERMS_OF_SERVICE}
					target="_blank"
					rel="noopener noreferrer"
					sx={{
						textDecoration: 'underline',
						transition: '0.3s ease',
						'&:hover': { color: 'text.hover' },
					}}
				>
					{termsOfService}
				</Link>
			</Typography>
		</StyledDialog>
	);
};

export default DialogMatureContent;
