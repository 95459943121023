import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { MoreVert } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { openModal } from 'store/storeSlices/sliceApp';
import { modalsKeys } from 'store/common/keys';

import strings from 'constants/strings';
import StyledIconButtonMui from 'components/StyledWrappers/StyledIconButtonMui';
import StyledMenu from 'components/StyledWrappers/StyledMenu';
import StyledMenuItem from 'components/StyledWrappers/StyledMenuItem';

const { deleteItem, deleteDiscordProject, rename } = strings;
const { PROJECT_DELETE, PROJECT_EDIT_TITLE } = modalsKeys;

type Props = {
	projectId: string;
	projectTitle: string;
	isDiscordProject: boolean;
};

const BtnMoreProjectCard: React.FC<Props> = ({ projectId, projectTitle, isDiscordProject }) => {
	const theme = useTheme();
	const dispatch = useStoreDispatch();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setIsMenuOpen(true);
	};

	const handleOnClose = () => {
		setAnchorEl(null);
		setIsMenuOpen(false);
	};

	const handleOnClickDelete = () => {
		if (!isDiscordProject) {
			dispatch(
				openModal({
					type: PROJECT_DELETE,
					data: { projectId },
				}),
			);
		}
	};

	const handleOnClickRename = () => {
		dispatch(
			openModal({
				type: PROJECT_EDIT_TITLE,
				data: { projectId, projectTitle },
			}),
		);
	};

	const conditionalTooltipDeleteItem = () => {
		if (isDiscordProject) {
			return deleteDiscordProject;
		}

		return '';
	};

	return (
		<>
			<StyledIconButtonMui onClick={handleOnClick} disableRipple sx={{ padding: '0' }}>
				<MoreVert fontSize="small" />
			</StyledIconButtonMui>

			{isMenuOpen && (
				<StyledMenu
					id="profileMenuBtn"
					open={isMenuOpen}
					anchorEl={anchorEl}
					onClose={handleOnClose}
					hasBorder
					anchorOrigin={{
						vertical: -40,
						horizontal: -115,
					}}
				>
					<StyledMenuItem
						padding="10px 16px"
						margin="0"
						onClick={handleOnClickRename}
						sx={{
							borderBottom: `1px solid ${theme.palette.background.surfaceHighest}`,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<EditIcon fontSize="small" sx={{ marginRight: '8px' }} />
							<Typography variant="h5" component="span">
								{rename}
							</Typography>
						</Box>
					</StyledMenuItem>

					<StyledMenuItem
						padding="12px 16px"
						margin="0"
						onClick={handleOnClickDelete}
						disabled={isDiscordProject}
					>
						<Tooltip title={conditionalTooltipDeleteItem()} placement="bottom" arrow>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<DeleteIcon fontSize="small" sx={{ marginRight: '8px' }} />
								<Typography variant="h5" component="span">
									{deleteItem}
								</Typography>
							</Box>
						</Tooltip>
					</StyledMenuItem>
				</StyledMenu>
			)}
		</>
	);
};

export default BtnMoreProjectCard;
