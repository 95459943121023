import React, {
	ChangeEventHandler,
	FocusEventHandler,
	KeyboardEventHandler,
	useState,
} from 'react';
import StyledFormInput from 'components/Common/StyledFormInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';

type Props = {
	value: string | number;
	name: string;
	placeholder?: string;
	error?: boolean;
	onChange: ChangeEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
};

const InputPassword: React.FC<Props> = ({
	value,
	name,
	placeholder,
	error,
	onChange,
	onBlur,
	onKeyDown,
}) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

	const handleRevealPasswordClick = () => {
		setIsPasswordVisible((prevState) => !prevState);
	};

	return (
		<StyledFormInput
			variant="filled"
			aria-label="pass"
			margin="normal"
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			error={error}
			fullWidth
			name={name}
			placeholder={placeholder}
			onKeyDown={onKeyDown}
			type={isPasswordVisible ? 'text' : 'password'}
			id={name}
			autoComplete="current-password"
			InputProps={{
				endAdornment: (
					<IconButton
						aria-label="toggle-sing-password-visibility"
						onClick={handleRevealPasswordClick}
						edge="end"
					>
						{isPasswordVisible ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				),
			}}
			InputLabelProps={{ shrink: true }}
		/>
	);
};

export default InputPassword;
