import React, { useRef, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Theme, useTheme } from '@mui/material';

import StyledDialogHorizontalCard from 'components/StyledWrappers/StyledDialogHorizontalCard';

import useStoreDispatch from 'store/hooks/useStoreDispatch';
import { closeModal } from 'store/storeSlices/sliceApp';
import { removePromoCode } from 'store/storeSlices/slicePayments';
import { DataModalStripeChekout } from 'store/types/typesModals';

import useSlicePayments from 'store/hooks/useSlicePayments';
import { useDeactivatePromoCodeMutation } from 'store/apis/apiProducts';
import StripeCheckoutForm from './StripeCheckoutForm';
import CheckoutSuccessMessage from './CheckoutSuccessMessage';

const getAppearance = (theme: Theme): Appearance => ({
	theme: 'night',
	variables: {
		fontFamily: 'Roboto, Arial, Verdana',
		fontLineHeight: '1.5',
		borderRadius: '4px',
		colorTextSecondary: theme.palette.text.active,
		colorText: 'rgb(168, 167, 168)', // theme.palette.text.active *Cannot apply color with opacity
		colorTextPlaceholder: 'rgb(72, 71, 72)', // theme.palette.text.disabled *Cannot apply color with opacity
		focusBoxShadow: `0 0 0 1px ${theme.palette.primary.light}, 0 0 0 1px ${theme.palette.primary.main}`,
		colorBackground: '#1a191b',
		colorIconTabSelected: theme.palette.text.hover,
		colorIconTab: 'rgb(168, 167, 168)',
	},
	rules: {
		'.Label': {
			color: theme.palette.text.hover,
		},
		'.Input': {
			borderRadius: '4px',
			backgroundColor: theme.palette.background.surfaceSolid,
			border: `1px solid ${theme.palette.text.disabled}`,
			color: theme.palette.text.hover,
			boxShadow: 'none',
		},
		'.Input:focus': {
			borderColor: theme.palette.primary.main,
		},
		'.Input--invalid, .Error': {
			color: theme.palette.error.main,
		},
		'.Block': {
			border: `1px solid ${theme.palette.text.disabled}`,
			boxShadow: 'none',
		},
		'.Tab': {
			backgroundColor: theme.palette.background.surfaceSolid,
			color: 'rgb(168, 167, 168)', // theme.palette.text.active *Cannot apply color with opacity
			borderRadius: '4px',
			border: `1px solid ${theme.palette.text.disabled}`,
			boxShadow: 'none',
		},
		'.Tab:hover': {
			backgroundColor: theme.palette.background.surfaceDarkLowest,
			color: 'rgb(168, 167, 168)', // theme.palette.text.active *Cannot apply color with opacity
		},
		'.Tab:focus': {
			borderColor: theme.palette.primary.main,
		},
		'.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
			backgroundColor: theme.palette.background.surfaceDarkLow,
			border: `1px solid ${theme.palette.primary.main}`,
			color: theme.palette.text.active,
		},
	},
});

const DialogStripeCheckout: React.FC<DataModalStripeChekout> = ({ product, paymentIntent }) => {
	const stripePromiseRef = useRef(loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`));
	const dispatch = useStoreDispatch();
	const { promoCode } = useSlicePayments();
	const [deactivatePromoCode] = useDeactivatePromoCodeMutation();
	const [paymentIntentId, setPaymentIntentId] = useState('');
	const theme = useTheme();
	const options: StripeElementsOptionsClientSecret = {
		appearance: getAppearance(theme),
		clientSecret: paymentIntent.clientSecret,
	};

	const handleCloseModal = () => {
		/* `promoCode` check Addreses WEBUI-3068: */
		/* if the transaction is cancelled or fails,
		 ** the user's discount still exists on the user's stripe account,
		 ** causing the next transaction to be discounted(without entering a discount code in webui)
		 */
		if (promoCode) {
			deactivatePromoCode();
		}
		dispatch(removePromoCode());
		dispatch(closeModal());
	};

	const conditionalContent = () => {
		/** 1. Submitting the stripe form return error or paymentIntent
		 **    If the paymentIntentId is not empty string then payment was successful
		 ** 2. If the applied Promo code applies 100% discount
		 ** => Renders success message
		 */
		if (paymentIntentId || paymentIntent.isFreeAfterPromo) {
			return <CheckoutSuccessMessage />;
		}

		// Renders Product and checkout form
		return (
			<Elements stripe={stripePromiseRef.current} options={options}>
				<StripeCheckoutForm
					product={product}
					totalPrice={paymentIntent.formattedPrice}
					setPaymentIntentId={setPaymentIntentId}
				/>
			</Elements>
		);
	};

	return (
		<StyledDialogHorizontalCard
			open
			onClose={handleCloseModal}
			aria-labelledby="checkout-dialog-title"
			aria-describedby="checkout-dialog-description"
			width="700px"
			sx={{
				overflow: 'auto',
				'@media screen and (max-width: 777px)': {
					'.MuiDialog-paper': {
						width: '342px',
					},
				},
			}}
			data-testid="checkout-modal"
		>
			{conditionalContent()}
		</StyledDialogHorizontalCard>
	);
};

export default DialogStripeCheckout;
