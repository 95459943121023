import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Tooltip, useTheme } from '@mui/material';
import StyledIconButtonAsset from 'components/StyledWrappers/StyledIconButtonAsset';

import strings from 'constants/strings';
import routesPaths from 'routes/paths';

import useSliceOpenedProjects from 'store/hooks/useSliceOpenedProjects';

import { ReactComponent as PlusIcon } from 'assets/img/icons/plus.svg';

const { MY_PROJECTS } = routesPaths;
const { openedProjectsLimitReached } = strings;

// TODO_NEXT find better name
const HeaderNewProjectTabButton: React.FC = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const { isOpenedProjectsLimitReached } = useSliceOpenedProjects();
	const handleOpenNewTab = () => {
		if (isOpenedProjectsLimitReached) return;

		navigate(MY_PROJECTS);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				padding: '8px',
				flexShrink: '0',
			}}
		>
			<Tooltip
				arrow
				title={isOpenedProjectsLimitReached ? <Box>{openedProjectsLimitReached}</Box> : ''}
			>
				{/* Need Box component otherwise disabled button prevents tooltip text from appearing */}
				<Box mt="-3px">
					<StyledIconButtonAsset
						sx={{
							width: '16px',
							height: '16px',
							padding: '2px',
							svg: { path: { stroke: theme.palette.text.secondary } },

							'&:hover': {
								backgroundColor: 'background.surfaceLow',
							},
						}}
						disabled={isOpenedProjectsLimitReached}
						onClick={handleOpenNewTab}
					>
						<PlusIcon />
					</StyledIconButtonAsset>
				</Box>
			</Tooltip>
		</Box>
	);
};

export default HeaderNewProjectTabButton;
