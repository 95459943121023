import { originFlowKeys, ORIGIN_PROJECT } from 'store/common/keys';
import useSliceOpenedProjects from './useSliceOpenedProjects';

/**
 * If the origin action IS from the Project page => returns false.
 *
 * If the origin action IS NOT from the Project page and the opened projects limit IS NOT reached => returns false.
 *
 * If the origin action IS NOT the from Project page and the opened projects limit IS reached => returns true.
 * @param origin key from originFlowKeys
 * @returns boolean
 */
const useIsOpenNewProjectDisabled = (origin: keyof typeof originFlowKeys) => {
	const { isOpenedProjectsLimitReached } = useSliceOpenedProjects();

	return !origin.includes(ORIGIN_PROJECT) && isOpenedProjectsLimitReached;
};

export default useIsOpenNewProjectDisabled;
