import React from 'react';
import { Box, Button, Skeleton, styled, Typography } from '@mui/material';
import { Product } from 'store/types/typesProducts';

import strings from 'constants/strings';
import { ReactComponent as CoinIcon } from 'assets/img/icons/coin.svg';
import { ReactComponent as CoinSilverIcon } from 'assets/img/icons/coinSilver.svg';
import { creditProductsRedeemTypes } from 'store/common/keys';
import useSlicePayments from 'store/hooks/useSlicePayments';

const StyledSummaryBox = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '16px',
});

const { checkoutPayButton, checkoutFormProduct, checkoutOrderSummary } = strings;
const { DEFAULT } = creditProductsRedeemTypes;

type Props = {
	product: Product;
};

const StripeCheckoutOrderSummarySkeleton: React.FC<Props> = ({ product }) => {
	const { promoCode } = useSlicePayments();

	const conditionalIcon = () => {
		// redeem type DEFAULT means Lifetime credits (not subscriptions)
		if (product.redeemType === DEFAULT) {
			return <CoinSilverIcon />;
		}

		return <CoinIcon />;
	};

	const conditionalContent = () => {
		if (promoCode) {
			return (
				<>
					<StyledSummaryBox>
						<Skeleton variant="text" width="33%" />
						<Skeleton variant="text" width="20%" />
					</StyledSummaryBox>
					<StyledSummaryBox>
						<Skeleton variant="text" width="40%" />
						<Skeleton variant="text" width="15%" />
					</StyledSummaryBox>
					<StyledSummaryBox
						sx={{
							paddingTop: '16px',
							borderTop: '1px solid',
							borderColor: 'text.disabled',
							marginTop: '28px',
						}}
					>
						<Skeleton variant="text" width="25%" />
						<Skeleton variant="text" width="20%" />
					</StyledSummaryBox>
				</>
			);
		}

		return (
			<StyledSummaryBox
				sx={{
					paddingTop: '16px',
					borderTop: '1px solid',
					borderColor: 'text.disabled',
					marginTop: '28px',
				}}
			>
				<Skeleton variant="text" width="25%" />
				<Skeleton variant="text" width="30%" />
			</StyledSummaryBox>
		);
	};

	return (
		<>
			<Box>
				<Typography
					variant="h3"
					sx={{
						padding: '21px 0',
						borderBottom: '1px solid',
						borderColor: 'text.disabled',
						marginBottom: '28px',
					}}
				>
					{checkoutOrderSummary}
				</Typography>

				<StyledSummaryBox>
					<Typography variant="h4">{checkoutFormProduct}</Typography>
					<Box display="flex" alignItems="center">
						{conditionalIcon()}
						<Typography
							variant="h4"
							sx={{
								display: 'flex',
								alignItems: 'center',
								marginLeft: '8px',
								lineHeight: '14px',
							}}
						>
							{product.name}
						</Typography>
					</Box>
				</StyledSummaryBox>

				{conditionalContent()}
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button variant="primary" type="button" disabled>
					{checkoutPayButton}
				</Button>
			</Box>
		</>
	);
};

export default StripeCheckoutOrderSummarySkeleton;
