import Radio from '@mui/material/Radio';
import { useTheme } from '@mui/system';
import { ReactComponent as UncheckedIcon } from 'assets/img/icons/checkbox-blank.svg';
import { ReactComponent as CheckedIcon } from 'assets/img/icons/checkbox-filled.svg';

type StyledRadioButtonProps = {
	disabled?: boolean;
};

function StyledRadioButton({ disabled, ...rest }: StyledRadioButtonProps) {
	const theme = useTheme();

	return (
		<Radio
			icon={
				<UncheckedIcon
					fill={disabled ? theme.palette.text.disabled : theme.palette.text.selected}
				/>
			}
			checkedIcon={<CheckedIcon fill={theme.palette.primary.light} />}
			disabled={disabled}
			{...rest}
		/>
	);
}

export default StyledRadioButton;
