import React from 'react';
import { Box } from '@mui/material';

import { colors } from 'styles/colors';

import { Model } from 'store/types/typesModels';

import Spinner from 'components/Common/Spinner';
import DialogModelsDescription from './DialogModelsDescription';
import DialogModelsImagesGrid from './DialogModelsImagesGrid';

type Props = {
	isFetchingContent: boolean;
	selectedModel: Model;
};

const DialogModelsBody: React.FC<Props> = ({ isFetchingContent, selectedModel }) => {
	const { modalDescription, key } = selectedModel;

	return (
		<Box
			flex="1"
			borderTop={`1px solid rgba(${colors.surfaceRGB}, 0.12)`}
			borderBottom={`1px solid rgba(${colors.surfaceRGB}, 0.12)`}
		>
			{isFetchingContent ? (
				<Box height="100%" display="flex" alignItems="center">
					<Spinner margin="auto" />
				</Box>
			) : (
				<>
					<DialogModelsDescription description={modalDescription} />
					<Box
						padding={modalDescription ? '0 24px' : '24px 24px 0'}
						height={modalDescription ? 'calc(100% - 100px)' : '100%'}
					>
						<DialogModelsImagesGrid selectedModelKey={key} />
					</Box>
				</>
			)}
		</Box>
	);
};

export default DialogModelsBody;
